export const FETCHING_ACCOUNT = 'FETCHING_ACCOUNT';
export const FETCHING_DOCUMENTS = 'FETCHING_DOCUMENTS';
export const CREATING_ACCOUNT = 'CREATING_ACCOUNT';
export const UPDATING_ACCOUNT = 'UPDATING_ACCOUNT';
export const DELETING_ACCOUNT = 'DELETING_ACCOUNT';
export const SYNCHRONIZING_ACCOUNT = 'SYNCHRONIZING_ACCOUNT';
export const FETCHING_LASTSYNCH_ACCOUNT = 'FETCHING_LASTSYNCH_ACCOUNT';
export const FETCHING_MFA_DATA = 'FETCHING_MFA_DATA';
export const SENDING_MFA_CODE = 'SENDING_MFA_CODE';
export const FETCHING_USERS = 'FETCHING_USERS';
export const FETCHING_USER = 'FETCHING_USER';

