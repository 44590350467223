import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Link, useParams } from 'react-router-dom';


// Localization
import _ from '../../../../constants/i18n/engine';
import T9n from '../../../../constants/i18n/translations';

import { SORT_BY_ORDER, TAB } from '../../../../constants/utils';
import { AgentListBodyItem } from './agent_list-body_item';
// import { normalize } from 'path';




export const AgentListBody = ({ newOrder, accounts, searchTerm, agents, searchFilterListAgents, setSearchFilterListAgents, setShowDetail, showDetail, tab, setTab, setInitialAgent, initialAgent, accountState }) => {

    const { userId } = useParams();
    useEffect(() => {

        const diacriticsSearchTem = searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        if (!R.isNil(agents)) {

            const agentNewList = agents.filter(a => !R.isNil(a.displayName) ? a.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().includes(diacriticsSearchTem) : a.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().includes(diacriticsSearchTem));
            const agentsWithAccount = [];
            const agentsWithoutAccount = [];

            agentNewList.forEach(agent => {
                let accountsForAgent = accounts?.filter(a => a.agentId === agent.id) || [];

                if (!R.isNil(accountsForAgent) && accountsForAgent.length > 0) {
                    agentsWithAccount.push(agent);
                } else {
                    agentsWithoutAccount.push(agent);
                }

            });


            let listsConcated = agentsWithAccount.concat(agentsWithoutAccount);

            switch (newOrder) {

                case SORT_BY_ORDER.ASCENDING:

                    agentsWithAccount.sort((a, b) => (a.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase() > b.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) ? 1 : -1);
                    agentsWithoutAccount.sort((a, b) => (a.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase() > b.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) ? 1 : -1);
                    listsConcated = agentsWithAccount.concat(agentsWithoutAccount);
                    //setSearchFilterListAgents(listsConcated);

                    break;

                case SORT_BY_ORDER.DESCENDING:

                    agentsWithAccount.sort((a, b) => (a.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase() > b.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) ? -1 : 1);
                    agentsWithoutAccount.sort((a, b) => (a.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase() > b.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()) ? -1 : 1);
                    listsConcated = agentsWithAccount.concat(agentsWithoutAccount);
                    //setSearchFilterListAgents(listsConcated);

                    break;


                default:
                    //setSearchFilterListAgents(agentNewList);
                    listsConcated = agentsWithAccount.concat(agentsWithoutAccount);
            }

            if (tab === TAB.MY_ACCOUNTS && searchTerm.length > 0) {
                setTab(TAB.ALL_AGENTS);
            }

            if (tab === TAB.MY_ACCOUNTS && searchTerm.length === 0) {
                setSearchFilterListAgents(agentsWithAccount);
            } else {
                setSearchFilterListAgents(listsConcated);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agents, searchTerm, newOrder, tab, accountState]);


    return (
        <div className="container container-list">
            <h2 className="sr-only visually-hidden">SBX Webview - Agents: List</h2>
            <ol className="list list-group list-unstyled mt-3">
                {R.isNil(agents) ?

                    <li className="list-group-item supplier-item progress rounded-pill border-none mb-2" >
                        <div className="progress-bar progress-bar-dark progress-bar-striped w-100 progress-bar-animated bg-faded" role="progressbar"></div>
                    </li>

                    :
                    R.isEmpty(searchFilterListAgents) && searchTerm.length !== 0 ?
                        <li className="flex-cloumn justify-content-center mt-5">
                            <div className="text-center">
                                <h1 className="display-3 mt-5"><i className="bi bi-emoji-neutral-fill text-default text-body-66"></i></h1>
                                <p className="mb-0 fs-5 d-flex justify-content-center align-items-center flex-wrap">{_(T9n.yourSearch)} "<strong className="d-inline-block text-truncate mb-0 container-word" >{searchTerm}</strong> "{_(T9n.didNotMatch)}</p>
                                <p className=" text-center ff-aux  fw-medium mt-0">{_(T9n.spelledCorrectly)}</p>
                            </div>
                        </li>
                        :
                        <React.Fragment>
                            {!R.isEmpty(searchFilterListAgents) && searchFilterListAgents.map((agent, id) => (<AgentListBodyItem accounts={accounts} agent={agent} key={id} searchTerm={searchTerm} newOrder={newOrder} setShowDetail={setShowDetail} showDetail={showDetail} setInitialAgent={setInitialAgent} initialAgent={initialAgent} accountState={accountState} />))}
                            {tab === TAB.MY_ACCOUNTS ?
                                R.isNil(accounts) || R.isEmpty(accounts) || R.isEmpty(searchFilterListAgents) ?
                                    <div className="db my-auto ms-0">
                                        {/* <div className="container container-list my-auto ms-0"> */}
                                        <h1 className="display-3 mb-4 mt-5"><i className="bi bi-bounding-box text-default text-body-66"></i></h1>
                                        <h5 className="sr-only visually-hidden">Agent - Empty</h5>
                                        <div className="display-4 lh-1 ls-n1 mb-3 fw-bold">{_(T9n.LooksLike)} <br />{_(T9n.itsEmpty)}</div>
                                        <p className="text-lg mb-0">{_(T9n.noAccountConfigured)}</p>
                                        {/* <p className="text-lg mb-4 text-cover-88">There are no Agent with Accounts.</p> */}
                                        {/* <Link to="/agents" className="btn btn-warning">Show all Agents </Link> */}
                                        <Link to={`/u/${userId}/agents`} className="btn p-0 text-decoration-none" onClick={() => setTab(TAB.ALL_AGENTS)}>
                                            <span className="text-dark align-middle">{_(T9n.showAgentsList)}</span>
                                            <i className="bi bi-arrow-right-short text-primary fs-3 align-middle"></i>
                                        </Link>
                                    </div>
                                    :
                                    <li className="bg-body-tertiary p-2 my-2 rounded">
                                        <div className="row align-items-center gx-2">
                                            <div className="col-7 col-md-9 col-lg">
                                                <h3 className="o-88 px-3 ff-aux fw-medium fs-aux lh-ase my-auto">{_(T9n.dontSee)}</h3>
                                            </div>
                                            <div className="col col-lg-auto text-end justify-content-end">
                                                <button className="btn btn-primary fw-medium lh-base border-none my-auto" onClick={() => setTab(TAB.ALL_AGENTS)}>
                                                    <i className="icns bi bi-plus fs-4 lh-1 align-middle d-none d-sm-inline-block"></i>
                                                    <span className="mx-sm-2 fs-md align-middle">{_(T9n.addAgent)}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                :
                                ""}
                        </React.Fragment>
                }
            </ol>
        </div >
    );
};


