import React from "react";
import _ from "../../../../constants/i18n/engine";
import { Link, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import * as R from "ramda";
import T9n from "../../../../constants/i18n/translations";

import * as AuthLoadings from '../../../../constants/loadings/auth';
import { forEachChild } from "typescript";



export const AgentDetailsDefaultSideNav = ({ agent, agentAccounts, selectedAccount, hasNewAccount, hasAccounts }) => {

    const accountState = useSelector(state => state.auth);
    const { userId } = useParams();

    let isNewAccount = selectedAccount.customerAccountId === "";
    const newAccountExists = hasAccounts && hasNewAccount;

    //var orderedBySyncState =  agentAccounts.reduce
    const syncStateOrder = [
        6,  // WrongCredentials
        20, // ResetPasswordRequired
        12, // CheckAccount
        22, // PersonalNotification
        14, // AdditionalAuthenticationRequired
        25, // WrongOptionalCredentials
        26, // WrongMFACode
        27, // ExpiredMFACode
        28, // IdentityProviderNotLinkedToAccount
        29, // PendingUserValidation
        1,  // Completed
        2,  // CompletedNothingToDownload
        3,  // CompletedNothingNewToDownload
        17, // WebsiteInMaintenance
        19, // ResetPasswordWarning
        21, // ServerUnavailable
        23, // TemporaryServerError
        24, // CaptchaFound
        13, // AccountBlocked
        30, // LoggedOutDuringDownload
        32, // BlockedByWebsiteProtectionService
        33, // AdditionalAuthenticationRequiredNotMFA
        4,  // CompletedWithMissingDocs
        5,  // CompletedWithErrors
        7,  // UnexpectedAccountData
        11, // DematerialisationNeeded
        15, // LoginPageChanged
        16, // WelcomePageChanged
        18, // WebsiteChanged
        31, // ProxyFailure
        0,  // NewAccount
        8,  // Scheduled
        9,  // Pending
        10,  // InProgress
        100
    ];

    const getIndex = (synch) => synch <= -1 ? 100 : synch;
    let sortedagentAccounts = [...agentAccounts].sort((a, b) => {
        return syncStateOrder.indexOf(getIndex(a?.lastSynch?.synchronizationStateDetails)) -
            syncStateOrder.indexOf(getIndex(b?.lastSynch?.synchronizationStateDetails));
    });

    const newCreated = sortedagentAccounts.find(a => R.isEmpty(a.customerAccountId));
    sortedagentAccounts = sortedagentAccounts.filter(a => !R.isEmpty(a.customerAccountId));
    if (!R.isNil(newCreated)) {
        sortedagentAccounts = [...sortedagentAccounts, newCreated];
    }

    return (
        <aside className="aside flex-row flex-md-column flex-wrap aling-items-center mb-4 sticky-top h-md-inherit scroll-md-auto">
            <h1 className="sr-only visually-hidden">Filters</h1>
            <div className="container-fluid">
                <ul className="nav nav-pills nav-fill flex-row flex-md-column mb-md-2 me-3" id="account--menu-nav" >
                    {sortedagentAccounts.map((account, i) => {

                        const lastSynch = account.lastSynch;
                        let active = account.customerAccountId === selectedAccount.customerAccountId;

                        return (
                            <li key={"nav-item-account-" + i} className="nav-item">
                                <Link to={account.customerAccountId ? `/u/${userId}/agents/${agent.id}/${account.customerAccountId}` : `/u/${userId}/agents/${agent.id}/new-account`} className={"nav-link fs-aux lh-lg text-start flex-row text-capitalize text-inherit" + (active ? " bg-body" : "")}
                                    onClick={() => {
                                        // setAgentAccounts({
                                        // 	...agentAccounts,
                                        // 	selectedAccountId: account.customerAccountId,
                                        // });

                                        //history.push(`/agents/${agent.id}/${account.customerAccountId}`);

                                    }
                                    }>
                                    {/* {R.isNil(lastSynch) || (lastSynch.synchronizationState !== 5) || (lastSynch.synchronizationStateDetails === 1) || (lastSynch.synchronizationStateDetails === 2) || (lastSynch.synchronizationStateDetails === 3)?
                                        "" :
                                        <i className="icns bi bi-exclamation-triangle-fill me-2 text-danger"></i>
                                    } */}
                                    {/* {R.isNil(lastSynch)  || (lastSynch.synchronizationState !== 5) || (lastSynch.synchronizationStateDetails === 1) || (lastSynch.synchronizationStateDetails === 2) || (lastSynch.synchronizationStateDetails === 3)  ?
                                        "" :
                                        <i className="icns bi bi-exclamation-triangle-fill me-2 text-danger"></i>
                                    } */}
                                    {!R.isNil(lastSynch) && (lastSynch.synchronizationStateDetails === 6 ||
                                        lastSynch.synchronizationStateDetails === 12 ||
                                        lastSynch.synchronizationStateDetails === 20 ||
                                        lastSynch.synchronizationStateDetails === 22 ||
                                        lastSynch.synchronizationStateDetails === 25 ||
                                        lastSynch.synchronizationStateDetails === 26 ||
                                        lastSynch.synchronizationStateDetails === 27 ||
                                        lastSynch.synchronizationStateDetails === 28 ||
                                        lastSynch.synchronizationStateDetails === 29
                                    ) ?
                                        <i className="icns bi bi-exclamation-triangle-fill me-2 text-danger"></i>
                                        :
                                        ""
                                    }
                                    <span className={"flex-grow-1 me-2" + (active ? "" : " text-body-66 text-body-88-hover")} > {(R.isNil(account.name) || R.isEmpty(account.name)) ? 'Nouveau Compte' : account.name} </span>
                                    <i className={"icns bi bi-chevron-right text-body" + (active ? "" : " text-body-66")} ></i>
                                </Link>
                            </li>
                        );
                    })}
                    {/* accountState.isLoading.includes(AuthLoadings.CREATING_ACCOUNT) */}
                    {
                        accountState.isLoading.includes(AuthLoadings.CREATING_ACCOUNT) &&
                        <li className="nav-item rounded flex-row align-items-center">
                            <div><span className="spinner-border text-primary spinner-border-sm ms-1 align-middle " role="status"><span className="visually-hidden">Loading...</span></span></div>
                            <p className="my-0 nav-link fs-aux lh-lg text-start flex-row text-capitalize text-inherit text-body-66 align-middle align-items-center">{_(T9n.creatingAccount)}</p>

                        </li>
                    }
                </ul>

                {/* <button type="button" className={`btn text-start px-0 text-body-88-hover fw-medium ${isNewAccount || agent.agentCurrentState || newAccountExists ? "disabled btn-disabled" : ""}`} */}
                <Link to={`/u/${userId}/agents/${agent.id}/new-account`} className={`btn text-start px-0 text-body-88-hover fw-medium border-0 ${isNewAccount || agent.agentCurrentState || newAccountExists ? "  disabled btn-disabled" : ""}`}
                    onClick={() => {


                        // setAgentAccounts({
                        // 	...agentAccounts,
                        // 	currentAgentAccounts:
                        // 		agentAccounts.currentAgentAccounts.concat(newAccount),
                        // 	selectedAccountId: newAccount.id,
                        // });
                        //setAgentAccounts([...agentAccounts, newAccount]);
                        //setSelectedAccount(newAccount);


                    }}
                >
                    <i className="icns text-primary bi bi-plus h3 me-1 my-auto align-middle"></i>
                    {_(T9n.add_account)}
                </Link>
            </div>
        </aside>
    );
};
