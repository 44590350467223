import * as ActionTypes from '../constants/actiontypes/agent';
import * as Loadings from '../constants/loadings/agent';
import * as Agents from '../constants/models/agents';



const AgentReducer = (state = {
    isLoading: [],
    isError:[],
    agents: undefined,
    includeLogo: false,


}, action) => {
    switch (action.type) {
        
        case ActionTypes.FETCH_AGENT_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.FETCH_AGENT_FAILURE);
            
            if (!state.isLoading.includes(Loadings.FETCHING_AGENT)){
                state.isLoading.push(Loadings.FETCHING_AGENT);
            }
            return { ...state };
        case ActionTypes.FETCH_AGENT_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_AGENT);
            state.isError = state.isError.filter(e => e !== ActionTypes.FETCH_AGENT_FAILURE);

            if (action.data.length > 0) {
                    state.agents = action.data.map(m => new Agents(m));
            }  
                return { ...state };
        
        case ActionTypes.FETCH_AGENT_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_AGENT);
            state.isError = [...state.isError, ActionTypes.FETCH_AGENT_FAILURE]
            return { ...state };
        
            
        default:
            return state;
    }
}
export default AgentReducer;