function Account(data) {
    let account = data || {};
    this.id = account.id;
    this.customerAccountId = account.customerAccountId;
    this.customerUserId = account.customerUserId;
    this.name = account.name;
    this.agentId = account.agentId;
    this.credentials = account.credentials;
    this.mode = account.mode;
    this.additionalAuthenticationData = account.additionalAuthenticationData;
    this.lastSynch = account.lastSynch;
  
}
module.exports = Account;  