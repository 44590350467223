import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";

import * as R from 'ramda';
import * as Loadings from '../../constants/loadings/agent';
import * as AuthLoadings from '../../constants/loadings/auth';
import * as CustomLoadings from '../../constants/loadings/customization';
import * as AgentsTypes from '../../constants/actiontypes/agent';
import _ from '../../constants/i18n/engine';
import T9n from '../../constants/i18n/translations';

import { getAllAgents } from '../../actions/agent-api';
import { CodeAuthentication, RedirectIdentityProvider, getAccounts, getUser, getUsers } from '../../actions/auth-api';

import { AgentList } from './agent_list';
import { AgentDetails } from './agent_details';
import { AgentListHeader } from './agent_list-header';
import { AgentListFavourite } from './agent_list-favourite';
import { getCustomization } from 'src/actions/customization-api';
import { ThemeService } from 'src/constants/utils/themeService';
import jwtDecode from 'jwt-decode';
import { axios } from 'src/lib/axios';

export const Agents = () => {

    const { userId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { agentId } = useParams();
    const agentState = useSelector(state => state.agents);
    const accountState = useSelector(state => state.auth);
    const featureState = useSelector(state => state.feature);
    const customizationState = useSelector(state => state.customization);

    const [searchTerm, setSearchTerm] = useState("");
    const [favoriteAgents, setFavoriteAgents] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [showDetailRoute, setShowDetailRoute] = useState(history.location.pathname);


    useEffect(() => {
        dispatch(getUser(userId));
        // eslint-disable-next-line 
    }, [userId]);

    useEffect(() => {

        // (async () => {
        //     await ThemeService(customizationState.customization).init();
        // })();
        // dispatch(getCustomization())
        //     .then((data) => { ThemeService(data).init(); });

        // eslint-disable-next-line 
    }, []);


    useEffect(() => {
        if (R.isNil(agentState.agents) &&
            !R.isNil(accountState.user)) {
            dispatch(getAllAgents());
            dispatch(getAccounts());
        }
        // eslint-disable-next-line 
    }, [agentState.agents, accountState.user]);


    useEffect(() => {
        setShowDetailRoute(history.location.pathname);
        // eslint-disable-next-line 
    }, [history.location.pathname]);


    //SHOW A LOADING

    if (accountState.isLoading.includes(AuthLoadings.FETCHING_USER) ||
        agentState.isLoading.includes(Loadings.FETCHING_AGENT) ||
        accountState.isLoading.includes(AuthLoadings.FETCHING_ACCOUNT) ||
        customizationState.isLoading.includes(CustomLoadings.FETCHING_CUSTOMIZATION)
    ) {
        return (
            <div className="flex-column h-100 align-itemns-center">
                <div className="container container-narrow text-center my-auto px-xl-5">
                    <div className="list-group-item supplier-item progress rounded-pill border-none mb-2 py-0" >
                        <div className="progress-bar progress-bar-striped w-100 progress-bar-animated bg-primary" role="progressbar"></div>
                    </div>
                </div>
            </div>
        );
    }

    if (R.isNil(agentState.agents) || R.isNil(accountState.user)) {

        //SHOW A TOAST

        if (agentState.isError.includes(AgentsTypes.FETCH_AGENT_FAILURE)) {
            return (
                <div className="flex-column flex-lg-row h-100 align-items-center justify-content-center">
                    <div className="brand brand-logo me-md-3 fluid bg-none" title="CloudAgents" aria-label="CloudAgents" role="button"></div>
                    <p className="text-start border-start border-none border-lg-default text-center text-lg-start px-3 mb-0">{_(T9n.errorLoadingAgents)} <br /> {_(T9n.errorRefreshPage)} <a href="mailto:support@securibox.eu">{_(T9n.contactUs)}</a>!</p>
                </div>
            );
        }
        return null;
    }


    return (
        <React.Fragment>
            <h1 className="sr-only visually-hidden">SBX Cloudagents - Webview</h1>
            <AgentListHeader accountState={accountState} searchTerm={searchTerm} agents={agentState.agents} setSearchTerm={setSearchTerm} />
            {/* <main className={"layout" + (!showDetail || showDetailRoute === "/agents" || showDetailRoute === "/agents/" ? "" : " show-view")} id="main"> */}
            <main className={"layout" + (!showDetail || R.isNil(agentId) ? "" : " show-view")} id="main">
                {!R.isNil(featureState.feature) && featureState.feature.activateFavoriteAgents && <AgentListFavourite agents={agentState.agents} accounts={accountState.accounts} favoriteAgents={featureState.feature.favoriteAgents} setFavoriteAgents={setFavoriteAgents} />}
                <AgentList searchTerm={searchTerm} agents={agentState.agents} accounts={accountState.accounts} accountState={accountState} setShowDetail={setShowDetail} showDetail={showDetail} />
                <AgentDetails setShowDetail={setShowDetail} showDetail={showDetail} />
            </main>
        </React.Fragment>
    );
};
