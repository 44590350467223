import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import _ from '../../../../constants/i18n/engine';
import T9n from '../../../../constants/i18n/translations';

import { submitMfaCode, synchronizeAccountById, getLastSynchronizationByAccountId } from '../../../../actions/auth-api';


import { Modal } from 'bootstrap';

export const AgentDetailsDefaultModalMFA = ({ selectedAccount, mfaCode, setMfaCode, isRunning }) => {
    const MfaForm = useRef(null);
    const dispatch = useDispatch();

    const MFARef = useRef();
    var MFAModal = document.getElementById('MFAModal');

    // var modal = new Modal(MFAModal, {});
    var modal = Modal.getInstance(MFAModal, {});

    // const [modal, setModal] = useState(null);

    // useEffect(() => {
    // 	setModal(
    // 		new Modal(MFAModal.current)
    // 	)
    // }, []);

    return (
        <div className="modal modal-auto fade z-max" id="MFAModal" ref={MFARef} tabIndex={-1} aria-labelledby="MFAModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered border-none">
                <div className="modal-content border-none rounded-1">
                    <div className="modal-body border-0 p-5">
                        <h5 className="sr-only visually-hidden" id="MFAModalLabel">MFA Modal</h5>
                        <i role="button" data-bs-dismiss="modal" aria-label="Close" className="user-select-none icns text-danger-hover bi bi-x fs-3 lh-1 float-right position-absolute top-0 end-0 p-4 z-2"></i>
                        <div className="flex-column flex-md-row align-items-start">
                            <div className="d-inline-block p-2 alert alert-primary text-primary border-none rounded-2 me-3 align-middle">
                                <i className="icns bi bi-arrow-clockwise fs-2 lh-1"></i>
                            </div>
                            <div className="block">
                                {/* <h1 className="h3 ff-aux ls-0 fw-medium ls-n1 mt-3 text-danger" id="addNew">{_(T9n.delete_account)}</h1> */}
                                <h1 className="h3 mb-0 text-primary" id="addNew">{_(T9n.mfa_code)}</h1>
                                <p className="fs-sm text-body-88 mb-0">{_(T9n.mfa_description)}</p>
                            </div>
                        </div>
                        <form className="block my-4" ref={MfaForm}>
                            <label className="label fw-demi text-uppercase small ls-1 mt-2 lh-1" htmlFor="mfaCode">{_(T9n.mfa_label)}</label>
                            <input type="password" className="form-control line-form" id="mfaCode" placeholder={_(T9n.mfa_placeholder)} value={mfaCode} onChange={(e) => setMfaCode(e.target.value)} />
                            <p className="form-text small text-gray mt-2" id="codeHelpBlock">{_(T9n.notReceiveIt)}
                                {isRunning ? <span role="button" disabled className="text-primary text-body-88-hover align-middle fw-bold border-0 bg-transparent text-body-88" style={{ cursor: "not-allowed" }}> {_(T9n.receiveNewOne)}</span> :
                                    <span role="button" className="text-primary text-body-88-hover align-middle fw-bold border-0 bg-transparent"
                                        onClick={() => {
                                            setMfaCode("");
                                            dispatch(
                                                synchronizeAccountById(
                                                    selectedAccount.customerAccountId,
                                                    selectedAccount.customerUserId,
                                                    false
                                                )
                                            );
                                            dispatch(getLastSynchronizationByAccountId(selectedAccount.customerAccountId));
                                        }}
                                    > {_(T9n.receiveNewOne)}</span>}
                            </p>
                        </form>
                        <div className="block border-0">
                            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => {
                                // e.preventDefault();
                                // if (selectedAccount.customerAccountId !== undefined) {
                                dispatch(submitMfaCode(selectedAccount.customerAccountId, mfaCode));
                                modal.hide();
                                // }
                            }}>
                                {_(T9n.Submit)}
                            </button>
                            <button type="button" className="btn text-body-88 text-body-hover" data-bs-dismiss="modal">{_(T9n.cancel)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};