import * as R from 'ramda';
import { decode } from './base64ToArrayBuffer';

export function ThemeService(data) {
    const {
        logoBase64Encoded,
        logoBase64EncodedName,
        baseFont,
        primaryColor:
        { hex, rgb: { r, g, b, a } } } = data;
    const isPrimaryColorDark = ((r + g + b) / 3) < 128;


    return {
        async init() {
            document.documentElement.style.setProperty('--sca-primary', hex);
            document.documentElement.style.setProperty('--sca-primary-rgb', `${r},${g},${b},${a}`);
            document.documentElement.style.setProperty('--sca-cover', 'var(--sca-primary)');
            document.documentElement.style.setProperty('--sca-cover-color-rgb', isPrimaryColorDark ? '255,255,255' : '0,0,0');
            document.documentElement.style.setProperty('--sca-cover-color', isPrimaryColorDark ? '#ffffff' : '#00000');
            document.querySelector("html").setAttribute("data-bs-theme", "dark");

            if (!R.isNil(logoBase64Encoded)) {
                let logo = await loadImageResult(logoBase64Encoded, logoBase64EncodedName);
                document.documentElement.style.setProperty('--sca-logo', logo);
                document.documentElement.style.setProperty('--sca-logo-width', '120px');
            }
            if (!R.isNil(baseFont) && baseFont.toLowerCase() === "serif") {
                document.documentElement.style.setProperty('--sca-headings-font-family', 'PT Serif, Georgia, Times New Roman, Times, serif');
                document.documentElement.style.setProperty('--sca-body-font-family', 'PT Serif, Georgia, Times New Roman, Times, serif');
            } else {
                document.documentElement.style.setProperty('--sca-headings-font-family', 'Manrope, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont');
                document.documentElement.style.setProperty('--sca-body-font-family', 'Manrope, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont');
            }
        }
    };
}

const loadImageResult = (base64, fileName) => {
    return new Promise((reject, resolve) => {
        const file = new Blob([decode(base64)], { type: "image/png" });
        file.name = fileName;
        const reader = new FileReader();
        reader.onload = (event) => {
            //document.documentElement.style.setProperty('--sca-logo', event.target.result);
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);

    });
};