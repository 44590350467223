import Axios from 'axios';

const API_URL = '';

const authRequestInterceptor = (config) => {
    const accessToken = sessionStorage.getItem('@cloudAgentsClientToken');
    if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
    }
    config.headers.Accept = 'application/json';
    return config;
};

export const axios = Axios.create({
    baseURL: API_URL
});

axios.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            sessionStorage.removeItem('@cloudAgentsClientToken');
            sessionStorage.removeItem('id_token');
            //window.location = getAuthenticationUrl();
        }
        throw error;
    }
);