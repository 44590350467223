
function Agents(data) {
    let agents = data || {};
    this.id = agents.id;
    this.agentId = agents.agentId;
    this.isEnabled = agents.isEnabled;
    this.name = agents.name;
    this.displayName = agents.displayName;
    this.description = agents.description;
    this.agentPeriodicity = agents.agentPeriodicity;
    this.agentCurrentState = agents.agentCurrentState;
    this.defaultCategory = agents.defaultCategory;
    this.categoryId = agents.categoryId;
    this.isTracked = agents.isTracked;
    this.isMFA = agents.isMFA;
    this.logos = agents.logos;
    this.countryCodes = agents.countryCodes;
    this.url = agents.url;
    this.documentTypes = agents.documentTypes;
    this.fields = agents.fields;
    this.category = agents.category;
    this.creationDate = agents.creationDate;
}
module.exports = Agents;