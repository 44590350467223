import React from 'react';

// Localization
// import _ from '../../../../constants/i18n/engine';
// import T9n from '../../../../constants/i18n/translations';

// Components
import { AgentDetailsDefaultReportStatus } from './agent_details-default_report-status';
import { AgentDetailsDefaultReportSync } from './agent_details-default_report-sync';


export const AgentDetailsDefaultReport = ({ selectedAccount, agent, lastSynch, mfaCode, setMfaCode, isRunning, hasAccounts, accountState }) => {
	return (
		<React.Fragment>
			{agent.agentCurrentState !== 0 ?
				<AgentDetailsDefaultReportStatus agent={agent} />
				: ''
			}
			{hasAccounts ?
				<AgentDetailsDefaultReportSync selectedAccount={selectedAccount} agent={agent} mfaCode={mfaCode} setMfaCode={setMfaCode} lastSynch={lastSynch} isRunning={isRunning} accountState={accountState} />
				:
				''
			}
		</React.Fragment>
	);
};
