import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import agents from './agents';
import auth from './auth';
import feature from './feature';
import customization from './customization';


/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (history) => combineReducers({
    router: connectRouter(history),
    agents,
    auth,
    feature,
    customization
});