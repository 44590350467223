import T9n from '../i18n/translations';
import _ from '../i18n/engine';


export const setTextFilter = (text = "") => ({
    type: "SET_TEXT_FILTER",
    text,
});

// SORT BY ORDER
export const SORT_BY_ORDER = {
  UNDEFINED: 'UNDEFINED',
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
  LAST_SYNC: "LAST_SYNC"
}


export const TAB = {
    MY_ACCOUNTS: 'MY_ACCOUNTS',
    ALL_AGENTS: 'ALL_AGENTS'
}

export const EDIT_MODE = {
  NONE: 'NONE',
  NEW: 'NEW',
  MFA: 'MFA',
  DELETE: 'DELETE'
}


export const SynchronizationState = 
    {
      NewAccount: 0,
      Created: 1,
      Running: 2,
      AgentFailed: 3,
      Delivering: 4,
      PendingAcknowledgement: 5,
      Completed: 6,
      ReportFailed: 7
    }

export const SYNCHDETAILSENUM = {

  0:"NewAccount",
  //Green
  1:"Completed", // SyncronizationState.Delivering -- SyncronizationState.Completed
  2:"CompletedNothingToDownload",  // SyncronizationState.Delivering -- SyncronizationState.Completed
  3:"CompletedNothingNewToDownload",  // SyncronizationState.Delivering -- SyncronizationState.Completed
  4:"CompletedWithMissingDocs",  // SyncronizationState.Delivering -- SyncronizationState.Completed
  5:"CompletedWithErrors",  // SyncronizationState.Delivering -- SyncronizationState.Completed
  //Red
  6:"WrongCredentials", // SynchronizationState.LoginFailed 
  7:"UnexpectedAccountData", // Unexpected
  //Green
  8:"Scheduled", // SynchronizationState.Created
  9:"Pending", // SyncronizationState.Created
  10:"InProgress", // SyncronizationState.Running
  //Orange
  11:"DematerialisationNeeded", // Not implemented by current agents
  12:"CheckAccount", // Used when invalid credentials are provided during account creation. No implementation.
  13:"AccountBlocked",
  14:"AdditionalAuthenticationRequired",
  15:"LoginPageChanged",
  16:"WelcomePageChanged",
  17:"WebsiteInMaintenance",
  18:"WebsiteChanged",
  19:"ResetPasswordWarning",
  20:"ResetPasswordRequired",
  21:"ServerUnavailable",
  22:"PersonalNotification",
  23:"TemporaryServerError",
  24:"CaptchaFound",
  25:"WrongOptionalCredentials",
  26:"WrongMFACode" // New state details to add
        
} 
    
export const synchIsRunning = (synchState) => {  // TODO: Review this!
  return synchState === SynchronizationState.Created || 
         synchState === SynchronizationState.Delivering ||
         synchState === SynchronizationState.Running;
}




export const DEFAULT_ACCOUNT_NAME = _(T9n.default_account_name);

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { SORT_BY_ORDER, TAB, DEFAULT_ACCOUNT_NAME, SYNCHDETAILSENUM };