import { SessionContext } from '../../services/session-context';

import T9n from './translations';

export default function _(translationDict, defaultTranslation = "Missing translation") {
    return translationDict[SessionContext.getLang()] || defaultTranslation;
}

function dateToDelta(date) {
    let delta = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);  // in seconds
    if (delta < 60) {
        return delta + ' ' + _(T9n.seconds);
    }
    delta = Math.round(delta / 60);
    if (delta < 60) {
        return delta + ' ' + _(T9n.minutes);
    }
    delta = Math.round(delta / 60);
    if (delta < 24) {
        return delta + ' ' + _(T9n.hours);
    }
    delta = Math.round(delta / 24);
    return delta + ' ' + _(T9n.days);
}

export { _, dateToDelta };