import * as ActionTypes from '../constants/actiontypes/feature';
import * as Loadings from '../constants/loadings/feature';
import Feature from '../constants/models/feature';

const data = {
    isActive: true,
    activateDisplayDocuments: true,
    activateFavoriteAgents: true,
    favoriteAgents: [
        {
            id: "a984b2a3-41ca-44b6-b391-82fcb0a45414",
            name: "1001Pharmacies"
        },
        {
            id: "eb87b6e1-f644-475c-995b-a46464d35e20",
            name: "3 Suisses"
        }
    ]
};

const FeatureReducer = (state = {
    isLoading: [],
    isError: [],
    feature: undefined,
}, action) => {
    switch (action.type) {

        case ActionTypes.FETCH_FEATURE_REQUEST:
            if (!state.isLoading.includes(Loadings.FETCHING_FEATURE)) {
                state.isLoading.push(Loadings.FETCHING_FEATURE);
            }
            return { ...state };
        case ActionTypes.FETCH_FEATURE_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_FEATURE);
            state.feature = new Feature(action.data);
            return { ...state };

        case ActionTypes.FETCH_FEATURE_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_FEATURE);
            return { ...state };


        default:
            return state;
    }
};
export default FeatureReducer;