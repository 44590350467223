import * as R from 'ramda';
import { EndPoints } from '../api-config';
export const SERVER_ADDR_V1 = EndPoints.backendHost;
// export const SERVER_ADDR_V1 = "https://sca-storage-staging.azurewebsites.net";

export const getDocumentsUrl = (pageNumber) => `${SERVER_ADDR_V1}/api/documents?${!R.isNil(pageNumber) ? 'pageNumber=' + pageNumber : ''}`;
export const getDocumentByIdUrl = (documentId) => `${SERVER_ADDR_V1}/api/documents/${documentId}`;
export const getDocumentContentByIdUrl = (documentId) => `${SERVER_ADDR_V1}/api/documents/${documentId}/content`;

export const identityProviderUrl = (state) => `${SERVER_ADDR_V1}/api/authentication/server${!R.isNil(state) ? '?state=' + state : ""}`;
export const identityProviderCodeUrl = () => `${SERVER_ADDR_V1}/api/authentication/code`;
export const getUsersUrl = () => `${SERVER_ADDR_V1}/api/users`;
export const getUserUrl = (userId) => `${SERVER_ADDR_V1}/api/users/${userId}/scaauthenticationtoken`;