import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router';

// Stylesheets
import './../assets/stylesheets/webview-app.scss';

// Components
import { Agents } from './agents';
import { CodeAuthentication, RedirectIdentityProvider, getUsers } from 'src/actions/auth-api';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import * as Loadings from '../constants/loadings/auth';



export const App = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const agentState = useSelector(state => state.agents);
    const autenticationState = useSelector(state => state.auth);
    const featureState = useSelector(state => state.feature);
    const customizationState = useSelector(state => state.customization);


    useEffect(() => {
        async function init() {
            const search = new URLSearchParams(location.search);
            const code = search.get('code');
            const state = search.get('state');
            const idFromEmail = search.get('id');

            if (!R.isNil(code)) {
                await CodeAuthentication(code, state);
            }
            let accessToken = sessionStorage.getItem("access_token");
            if (accessToken) {
                const decodedToken = jwtDecode(accessToken);
                const { exp } = decodedToken;
                const expirationDateTime = new Date(exp * 1000);
                const currentDateTime = new Date();

                if (expirationDateTime < currentDateTime) {
                    axios.defaults.headers.common['Authorization'] = "";
                    sessionStorage.removeItem("access_token");
                    await RedirectIdentityProvider(idFromEmail);
                    return;
                }
                axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
                try {
                    const [user] = await dispatch(getUsers());
                    if (location.pathname.includes('/u/')) {
                        history.push(location.pathname);
                        return;
                    }
                    history.push(`/u/${user.id}/agents`);
                } catch (error) {
                    history.push(location.pathname);
                }
            } else {
                await RedirectIdentityProvider(idFromEmail);
            }
        }
        init();
        // eslint-disable-next-line
    }, []);

    if (autenticationState.isLoading.includes(Loadings.FETCHING_USERS)) {
        return (
            <div className="flex-column h-100 align-itemns-center">
                <div className="container container-narrow text-center my-auto px-xl-5">
                    <div className="list-group-item supplier-item progress rounded-pill border-none mb-2 py-0" >
                        <div className="progress-bar progress-bar-striped w-100 progress-bar-animated bg-primary" role="progressbar"></div>
                    </div>
                </div>
            </div>);
    }
    if (R.isNil(autenticationState.users)) {
        return null;
    }

    return (
        <Switch>
            <Route path={["/u/:userId/agents/:agentId/:accountId", "/u/:userId/agents/:agentId/", "/u/:userId/agents"]} component={Agents} />
            <Route render={() => { return <Redirect exact to={`/agents`} />; }} />
        </Switch>
    );
};