import React, { useEffect } from "react";
import * as R from "ramda";
import { _, dateToDelta } from "../../../../../constants/i18n/engine";
import T9n from "../../../../../constants/i18n/translations";
import { useDispatch } from "react-redux";

import { Modal } from 'bootstrap';
import { synchronizeAccountById, getLastSynchronizationByAccountId } from "../../../../../actions/auth-api";
import { SYNCHDETAILSENUM } from "../../../../../constants/utils";
import * as Loadings from "../../../../../constants/loadings/auth";



export const AgentDetailsDefaultReportSync = ({ selectedAccount, agent, mfaCode, setMfaCode, lastSynch, isRunning, accountState }) => {

    const dispatch = useDispatch();
    const indisponibleAgent = agent.agentCurrentState !== 1 && agent.agentCurrentState !== 0;
    const WrongMFACode = 26;

    const ONE_MINUTE_IN_MILLISECONDS = 60000;
    const THIRTY_MINUTES = ONE_MINUTE_IN_MILLISECONDS * 30;


    // console.log('=============================');
    // console.log(`State Detail: ${lastSynch ? lastSynch.synchronizationStateDetails : ''}`);
    // console.log(`Agent isMFA: ${agent.isMFA}`);
    // console.log(lastSynch);
    // console.log('=============================');

    // if((dateNow - syncCreationDate) < THIRTY_MINUTES ){
    //   console.log('less than 30 minutes');
    // }

    useEffect(() => {
        const syncCreationDate = lastSynch && new Date(lastSynch.creationDate).getTime();
        const dateNow = new Date().getTime();
        const diffSyncDateToDateNow = dateNow - syncCreationDate;

        if (!R.isNil(lastSynch) && agent.isMFA === true) {

            var MFAModal = document.getElementById('MFAModal');
            var modal = Modal.getOrCreateInstance(MFAModal, {});

            if (lastSynch.synchronizationStateDetails === 14 && mfaCode === "" && (diffSyncDateToDateNow < THIRTY_MINUTES)) {
                modal.show();
            }

            if (lastSynch.synchronizationStateDetails === WrongMFACode || lastSynch.synchronizationStateDetails === 15) {
                modal.hide();
            }
        }
        // eslint-disable-next-line
    }, [lastSynch && lastSynch.synchronizationStateDetails, lastSynch]);

    return (
        agent.agentCurrentState === 0 ?
            <section className="block mb-4">
                <div className="container-fluid">
                    <h1 className="fs-5 my-2 text-truncate">
                        <i className="icns bi bi-arrow-clockwise align-middle"></i>
                        <span className="ms-2 o-1">{_(T9n.synchronization)}</span>
                    </h1>
                    <p className="small text-body-88 lh-base mb-3">{_(T9n.sync_description)}</p>
                    <button
                        type="button"
                        className={(isRunning || accountState.isLoading.includes(Loadings.SYNCHRONIZING_ACCOUNT) || accountState.isLoading.includes(Loadings.FETCHING_LASTSYNCH_ACCOUNT) ? "btn bg-body disabled" : "btn bg-body") && (indisponibleAgent ? "btn bg-body disabled" : "btn bg-body")}
                        disabled={isRunning || accountState.isLoading.includes(Loadings.SYNCHRONIZING_ACCOUNT) || accountState.isLoading.includes(Loadings.FETCHING_LASTSYNCH_ACCOUNT)}
                        onClick={() => {
                            setMfaCode("");
                            dispatch(
                                synchronizeAccountById(
                                    selectedAccount.customerAccountId,
                                    selectedAccount.customerUserId,
                                    false
                                )
                            );
                            dispatch(getLastSynchronizationByAccountId(selectedAccount.customerAccountId));
                        }}
                    >
                        <i className="text-info icns bi bi-arrow-clockwise fs-5 me-2 lh-1"></i>
                        {_(T9n.sync)}
                    </button>
                    {!R.isNil(lastSynch) && !indisponibleAgent ? (
                        <ul className="list-group sync mt-4">
                            <li className="sync-item flex-row flex-wrap rounded gx-2 bg-body p-2">
                                <div className="col-auto">
                                    {isRunning ?
                                        <i className="icns bi bi-question px-2 py-1 fs-5 fw-demi bg-faded text-default rounded me-3"></i>
                                        :

                                        (lastSynch.synchronizationStateDetails === 1) || (lastSynch.synchronizationStateDetails === 2) || (lastSynch.synchronizationStateDetails === 3) ?
                                            <i className="icns bi bi-check2-circle px-2 py-1 fs-5 fw-demi bg-danger-info text-info rounded me-3"></i>
                                            :
                                            <i className="icns bi bi-exclamation-triangle px-2 py-1 fs-5 fw-demi bg-danger-subtle text-danger rounded me-3"></i>
                                    }
                                </div>
                                <div className="col">
                                    {/* <h6 className="fs-xs text-body mb-0 lh-sm text-body-88 mt-1">
                Résultats de dernière synchronisation il y a <strong>{dateToDelta(lastSynch.creationDate)}</strong> :
              </h6> */}
                                    {isRunning ?
                                        <p className="fs-xs text-body mb-0 lh-sm text-body-88 mt-1">
                                            {_(T9n.synchronizing)}
                                        </p>
                                        :
                                        <p className="fs-xs text-body mb-0 lh-sm text-body-88 mt-1">
                                            {_(T9n.lastSynchronization)}<strong>{dateToDelta(lastSynch.creationDate)}</strong> :
                                        </p>
                                    }

                                    {isRunning ?

                                        <div className="sync-item  flex-row flex-wrap rounded gx-2 bg-body progress border-none mt-2">
                                            <div className="progress-bar progress-bar-dark progress-bar-striped w-100 progress-bar-animated bg-default" role="progressbar"></div>
                                        </div>
                                        :
                                        lastSynch.synchronizationStateDetails ?
                                            <React.Fragment>
                                                <div className="fs-aux text-body mb-1 lh-copy">
                                                    {
                                                        [_(T9n[SYNCHDETAILSENUM[lastSynch.synchronizationStateDetails]])].map((text, i) => {

                                                            const splitTextWithDocs = text.split("@@");
                                                            const splitText = text.split("&&");
                                                            const splitTextPersonalNotification = text.split("##");

                                                            return (
                                                                <div key={i}>
                                                                    {
                                                                        text.includes("@@") ?
                                                                            <> {splitTextWithDocs[0]} </>
                                                                            :
                                                                            text.includes("##") ?
                                                                                <> {splitTextPersonalNotification[0]} </>
                                                                                :
                                                                                text.includes("&&") ?
                                                                                    <>{splitText[0]}</>
                                                                                    :
                                                                                    text
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                                <div className="fs-md text-body mb-0 lh-copy text-body-88">
                                                    {
                                                        [_(T9n[SYNCHDETAILSENUM[lastSynch.synchronizationStateDetails]])].map((text, i) => {

                                                            const splitTextWithDocs = text.split("@@");
                                                            const splitText = text.split("&&");
                                                            const splitTextPersonalNotification = text.split("##");

                                                            return (
                                                                <div key={i}>
                                                                    {

                                                                        text.includes("@@") ?
                                                                            <>
                                                                                {lastSynch.downloadedDocs > 0 ?
                                                                                    lastSynch.downloadedDocs
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                {splitTextWithDocs[1]}
                                                                            </>
                                                                            :
                                                                            text.includes("&&" && "%") ?
                                                                                <> {splitText[1]} <a href={agent.url} target="_blank" rel="noreferrer" >{_(T9n.agentUrl)}</a> </>
                                                                                :
                                                                                text.includes("##" && "$") ?
                                                                                    <> <a href={agent.url} target="_blank" rel="noreferrer" >{_(T9n.agentUrl)}</a> {splitTextPersonalNotification[2]}</>
                                                                                    :
                                                                                    <>{splitText[1]} {splitText[2]}</>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </React.Fragment>
                                            :
                                            ""
                                    }
                                </div>
                            </li>
                        </ul>
                    ) : (
                        <div className="sync-item  flex-row flex-wrap rounded gx-2 bg-body progress border-none mt-4">
                            <div className="progress-bar progress-bar-dark progress-bar-striped w-100 progress-bar-animated bg-default" role="progressbar"></div>
                        </div>
                    )}
                </div>
            </section> : ""
    );
};
