import React from 'react';
import _ from '../../../../../../constants/i18n/engine';
import T9n from '../../../../../../constants/i18n/translations';



export const AgentDetailsDefaultAccountViewDelete = () => {

    return (
        <section className="block mb-3" id="delete-account-section">
            <div className="container-fluid">
                <h3 className="fs-5 my-2">
                    {/* <i className="icns bi bi-trash align-middle me-2"></i> */}
                    <span className="o-100">{_(T9n.delete) + " " + _(T9n.account)}</span>
                </h3>
                <div className="container-650 fs-md text-body-88 mb-4" >
                    {_(T9n.delete_information)}
                </div>
                <button type="button" className="btn bg-danger-subtle text-danger border-0" data-bs-toggle="modal" data-bs-target="#deleteModal">
                    <i className="icns fs-3 lh-1 bi bi-x align-middle"></i><span className="mx-2 fw-medium">{_(T9n.delete_button)}</span>
                </button>
                {/* <button type="button" className="btn alert alert-primary text-primary border-0 ms-1" data-bs-toggle="modal" data-bs-target="#MFAModal">
                        <i className="icns fs-3 lh-1 bi bi-code align-middle"></i><span className="mx-2 fw-medium">MFA</span>
                    </button> */}
            </div>
        </section>
    );

};

