import * as CloudAgents from 'cloudagents';
import jwt_decode from "jwt-decode";

function UndefinedAccessToken() {
    this.message = "You dont have an permission to access this page.";
    console.log(this.message);
    return this.message;
}
function UndefinedEnvironment() {
    this.message = "You dont have an Environment.";
    console.log(this.message);
    return this.message;
}
function ExpirationDateExceeded() {
    this.message = "expiration Date Exceeded.";
    console.log(this.message);
    return this.message;
}



export default class ClientJWT {

    constructor() {
        this.token = undefined;
        this.environment = undefined;
        this.jwt = undefined;
        this.client = undefined;
        this.authStrategy = undefined;
    }

    getClient() {
        this.token = sessionStorage.getItem('@cloudAgentsClientToken');
        this.environment = sessionStorage.getItem('@cloudAgentsEnvironment');
        if (!this.token) {
            throw new UndefinedAccessToken();
        }
        if (!this.environment) {
            throw new UndefinedEnvironment();
        }

        this.jwt = jwt_decode(this.token);
        const { exp } = this.jwt;
        const now = new Date().getTime();

        if (now > (exp * 1000)) {
            throw new ExpirationDateExceeded();
        }
        this.client = new CloudAgents.Client('https://' + this.environment + '/api/v1');
        this.authStrategy = new CloudAgents.BearerStrategy(this.token);
        this.client.use(this.authStrategy);

        return this.client;

    }
}







