import React from 'react';
import * as R from 'ramda';
import { _ } from '../../../../../constants/i18n/engine';
import T9n from '../../../../../constants/i18n/translations';



export const AgentDetailsDefaultReportStatus = ({ agent }) => {

    return (
        !R.isNil(agent) ?
            <section className="block mb-4">
                <div className="container-fluid">
                    <h1 className="fs-5 my-2 text-truncate"><i className="icns bi bi-exclamation-diamond align-middle"></i><span className="ms-2 o-1">{_(T9n.status)}</span></h1>
                    <p className="small text-body-88 lh-base mb-3">{_(T9n.status_description)}</p>
                    <ul className="list-group sync">
                        {agent.agentCurrentState === 1 ?
                            <li className="sync-item flex-row flex-wrap rounded gx-2 bg-body align-items-top p-2">
                                <div className="col-auto">
                                    <i className="icns btn-alert btn-maintenance bi bi-cone-striped px-2 py-1 fs-5 fw-demi  rounded me-3"></i>
                                </div>
                                <div className="col">
                                    <h6 className="fs-md mt-auto mb-1 fw-demi text-warning">{_(T9n.inMaintenance)}</h6>
                                    <p className="fs-aux text-body mb-1 lh-copy">{_(T9n.theCollector)} <strong>{agent.displayName}</strong> {_(T9n.hasBeenPutUnderMaintaince)}</p>
                                    <p className="fs-md text-body mb-0 lh-copy text-body-88">{_(T9n.atThisTimeNoAction)}</p>
                                </div>
                            </li>
                            :
                            <li className="sync-item flex-row flex-wrap rounded gx-2 bg-body align-items-top p-2">
                                <div className="col-auto">
                                    <i className="icns bi bi-x px-2 py-1 fs-5 fw-demi bg-danger-subtle text-danger rounded me-3"></i>
                                </div>
                                <div className="col">
                                    <h6 className="fs-md mt-auto mb-1 fw-demi text-danger">{_(T9n.unavailable)}</h6>
                                    <p className="fs-aux text-body mb-1 lh-copy">{_(T9n.theCollector)}<strong>{agent.displayName}</strong> {_(T9n.isCurrentlyUnavailable)}</p>
                                    <p className="fs-md text-body mb-0 lh-copy text-body-88">{_(T9n.atThisTimeNoAction)}</p>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </section>
            :
            ""
    );
};

