import React, { useEffect } from "react";
// import { Dropdown } from "bootstrap/dist/js/bootstrap.bundle";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";
import * as R from "ramda";

import _ from "../../constants/i18n/engine";
import T9n from "../../constants/i18n/translations";
import { SessionContext } from "../../services/session-context";


export const AgentListHeader = ({ accountState, searchTerm, setSearchTerm }) => {
    const { userId } = useParams();
    const handleChange = event => { setSearchTerm(event.target.value); };
    const signOutUrl = sessionStorage.getItem('signOutUrl');

    // useEffect(() => {
    //     const dropdownElementList = document.querySelectorAll('[data-bs-toggle="dropdown"]')
    //     const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl))
    //     return dropdownList;
    // }, []);

    return (
        <nav className="navbar cover sticky-lg-top" data-spy="affix" data-offset-top="80">
            <h1 className="sr-only visually-hidden">SBX Webview - Navigation</h1>
            <ul className="list-unstyled container-fluid my-auto">
                <li className="col-auto order-first col-min">
                    <div className="brand brand-logo me-md-3 fluid bg-none" title="CloudAgents" aria-label="CloudAgents" role="button"></div>
                </li>
                <li className="col col-md col-xl-auto flex-xl-grow-1 order-md-last align-items-center text-end">
                    {/* <button type="button" className="btn text-cover text-cover-88-hover bg-body bg-opacity-10">
                        <span className="fs-aux me-2">
                            {_(T9n.request)}
                            <span className="d-none d-lg-inline-block">
                                {_(T9n.an_agent)}
                            </span>
                        </span>
                        <i className="icns bi bi-cursor-fill fs-6 lh-1 align-middle text-primary"></i>
                    </button> */}
                    {!accountState.users ?
                        <button onClick={() => { const callbackURL = SessionContext.getCallbackURL(); const state = SessionContext.getState(); window.location.href = `${callbackURL}?state=${state}`; }} className="btn p-1 text-cover-44 text-cover-hover ms-1" aria-label="Close">
                            <span className="fs-aux visually-hidden sr-only">
                                Close
                            </span>
                            <i className="icns bi bi-x fs-4 lh-1 align-middle text-inherit"></i>
                        </button>
                        :
                        <div className="dropdown">
                            <button type="button" className="btn btn-if dropdown-toggle p-0 border-0" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="btn-text align-items-center">
                                    <i className="icns bi bi-person-fill fs-5 lh-1 text-primary align-middle"></i>
                                    <span className="align-middle mx-2">{accountState.users.find(u => u.id.toString() === userId)?.name}</span>
                                </span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-none shadow">
                                {
                                    accountState.users.map(user => (
                                        <li key={user.id} className={"dropdown-item" + (user.id.toString() === userId ? " text-bg-body-tertiary" : "")} role="button" onClick={() => { window.location = '/u/' + user.id + '/agents'; }}>
                                            <i className="icns bi bi-person-fill lh-1 me-xs text-primary align-middle"></i>
                                            <span className="fs-sm ms-2">
                                                {user.name}
                                            </span>
                                        </li>
                                    ))
                                }
                                <button className="dropdown-item" onClick={() => { window.location = signOutUrl; }} type="button">
                                    <i className="icns bi bi-logout lh-1 me-xs text-primary align-middle"></i>
                                    <span className="inline fs-sm ms-2">
                                        {_(T9n.logout)}
                                    </span>
                                </button>
                            </ul>
                        </div>
                    }
                </li>
                <li className="col-12 d-md-none my-1"></li>
                <li className="col col-md order-last order-md-1">
                    <div className="input-group fluid rounded-pill card flex-row border-none" id="searchgroup">
                        <label className="sr-only visually-hidden" htmlFor="search">Search</label>
                        <input id="search" value={searchTerm} onChange={handleChange} type="text" className="form-control fs-md lh-lg border-none bg-inherit rounded-inherit" placeholder={_(T9n.search)} name="search" aria-describedby="basic-addon2" />
                        <span className="input-group-text border-none bg-inherit rounded-inherit" id="basic-addon2" >
                            {/* <i className="icns bi bi-chevron-down lh-1"></i> */}
                            <i className="icns bi bi-search lh-1"></i>
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
    );
};


