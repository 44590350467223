
export class SessionContext {

    private static _urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    private static _parsedToken: Object;

    private static getURLSearchParams(): URLSearchParams {
        return SessionContext._urlParams;
    }

    public static redirectFailedAuth(): void {
        const url = SessionContext.getCallbackURL();
        if (url) {
            window.location.assign(url + "?state=FailedAuth");  // TODO: Define state
        } else {
            console.log('Missing callback URL. Redirect will not work');  // TODO: review this
        }
    }

    private static getParsedToken(): Object {        
        if (!SessionContext._parsedToken) {
            try {
                let base64Payload: string = SessionContext.getToken().split('.')[1];
                base64Payload = base64Payload.replace('-', '+').replace('_', '/');
                let parsedToken = JSON.parse(window.atob(base64Payload));  // TODO: atob *might* break in some cases. If so, fix it with https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings                
                const now: number = Math.round((new Date()).getTime() / 1000);
                if (now < parsedToken['nbf'] || parsedToken['exp'] < now) {
                    throw  Object.assign(
                        new Error("Invalid token period")
                    );
                }
                SessionContext._parsedToken = parsedToken;
            
            }
            catch (err) {
                SessionContext.redirectFailedAuth();
            }

       
        }
        
        
        return SessionContext._parsedToken;
    }

    private static _getParamValue(key: string): string {
        return SessionContext.getURLSearchParams().get(key);
    }

    public static getToken(): string {
        return SessionContext._getParamValue('token');
      
    }

    public static getCallbackURL(): string {
        return SessionContext._getParamValue('callback');
    }

    public static getLang(): string {
        return SessionContext._getParamValue('lang') || 'fr-FR';  // default lang
    }

    public static getState(): string {
        return SessionContext._getParamValue('state') || '';
    }

    public static getAudience(): string {
        console.log(SessionContext.getParsedToken()['aud'])
        return SessionContext.getParsedToken()['aud'];
        
    }

    public static forceIDFormat(): boolean {
        const forceId = SessionContext._getParamValue('forceIDFormat');
        return forceId && forceId.toLowerCase() === 'true';
    }

    public static getCustomerUserId(): string {
        return SessionContext.getParsedToken()['uid'];
    }

}