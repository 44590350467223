import * as R from 'ramda';
import * as ActionTypes from '../constants/actiontypes/auth';
import * as Loadings from '../constants/loadings/auth';
import * as Account from '../constants/models/account';
import * as Document from '../constants/models/document';


const AuthReducer = (state = {
    isLoading: [],
    users: undefined,
    user: undefined,
    isError: [],
    accounts: undefined,
    documents: [],
    isForced: true,

}, action) => {
    switch (action.type) {

        case ActionTypes.CLEAR_ACCOUNT_ERRORS:
            state.isError = [];
            state.isLoading = [];
            return { ...state };

        case ActionTypes.FETCH_USERS_REQUEST:
            if (!state.isLoading.includes(Loadings.FETCHING_USERS)) {
                state.isLoading.push(Loadings.FETCHING_USERS);
            }
            return { ...state };
        case ActionTypes.FETCH_USERS_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_USERS);
            return { ...state, users: action.data };

        case ActionTypes.FETCH_USERS_FAILURE:
            return { ...state };

        case ActionTypes.FETCH_USER_REQUEST:
            if (!state.isLoading.includes(Loadings.FETCHING_USER)) {
                state.isLoading.push(Loadings.FETCHING_USER);
            }
            return { ...state };
        case ActionTypes.FETCH_USER_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_USER);
            return { ...state, user: action.data };

        case ActionTypes.FETCH_USER_FAILURE:
            return { ...state };

        case ActionTypes.FETCH_ACCOUNT_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.FETCH_ACCOUNT_FAILURE);

            if (!state.isLoading.includes(Loadings.FETCHING_ACCOUNT)) {
                state.isLoading.push(Loadings.FETCHING_ACCOUNT);
            }
            return { ...state };
        case ActionTypes.FETCH_ACCOUNT_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_ACCOUNT);

            if (action.data.length > 0) {

                state.accounts = action.data.map(m => new Account(m));

            }
            return { ...state };

        case ActionTypes.FETCH_ACCOUNT_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_ACCOUNT);
            state.isError = [...state.isError, ActionTypes.FETCH_ACCOUNT_FAILURE];
            return { ...state };

        case ActionTypes.FETCH_ACCOUNT_DOCUMENTS_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE);

            if (!state.isLoading.includes(Loadings.FETCHING_DOCUMENTS)) {
                state.isLoading.push(Loadings.FETCHING_DOCUMENTS);
            }
            return { ...state };
        case ActionTypes.FETCH_ACCOUNT_DOCUMENTS_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_DOCUMENTS);
            state.documents = action.data.map(doc => new Document(doc));
            return { ...state };

        case ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_DOCUMENTS);
            state.isError = [...state.isError, ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE];
            return { ...state };


        case ActionTypes.ADD_ACCOUNT_REQUEST:
            if (!state.isLoading.includes(Loadings.CREATING_ACCOUNT)) {
                state.isLoading.push(Loadings.CREATING_ACCOUNT);
            }
            state.isError = state.isError.filter(e => e !== ActionTypes.ADD_ACCOUNT_FAILURE);
            return { ...state };

        case ActionTypes.ADD_ACCOUNT_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.CREATING_ACCOUNT);

            const lastAccount = new Account(action.data);
            lastAccount.created = true;

            state.accounts = R.isNil(state.accounts) ? [] : state.accounts;
            state.accounts = state.accounts.concat(lastAccount);

            state.accounts.forEach(account => {
                if (lastAccount.customerAccountId === account.customerAccountId) return;
                account.created = false;
            });

            return { ...state };

        case ActionTypes.ADD_ACCOUNT_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.CREATING_ACCOUNT);
            state.isError = [...state.isError, ActionTypes.ADD_ACCOUNT_FAILURE];
            return { ...state };

        case ActionTypes.UPDATE_ACCOUNT_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.UPDATE_ACCOUNT_FAILURE);

            if (!state.isLoading.includes(Loadings.UPDATING_ACCOUNT)) {
                state.isLoading = state.isLoading.concat(Loadings.UPDATING_ACCOUNT);
            }
            return { ...state };

        case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
            state.isLoading = state.isLoading.filter(l => l !== Loadings.UPDATING_ACCOUNT);

            state.accounts = state.accounts.map((item) => {
                if (item.customerAccountId !== action.data.customerAccountId) {
                    return item;
                }
                return new Account(action.data);
            });

            return { ...state };

        case ActionTypes.UPDATE_ACCOUNT_FAILURE:
            state.isLoading = state.isLoading.filter(l => l !== Loadings.UPDATING_ACCOUNT);
            state.isError = [...state.isError, ActionTypes.UPDATE_ACCOUNT_FAILURE];

            return { ...state };

        case ActionTypes.DELETE_ACCOUNT_SUCCESS:

            state.accounts = state.accounts.filter(s => s.customerAccountId !== action.customerAccountId);

            return { ...state };

        case ActionTypes.SYNCHRONIZE_ACCOUNT_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.SYNCHRONIZE_ACCOUNT_FAILURE);
            // state.isLoading = state.isLoading.filter(l => l !== Loadings.SYNCHRONIZING_ACCOUNT);

            if (!state.isLoading.includes(Loadings.SYNCHRONIZING_ACCOUNT)) {
                state.isLoading.push(Loadings.SYNCHRONIZING_ACCOUNT);
            }
            return { ...state };

        case ActionTypes.SYNCHRONIZE_ACCOUNT_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.SYNCHRONIZING_ACCOUNT);

            state.isForced = action.data;

            return { ...state };

        case ActionTypes.SYNCHRONIZE_ACCOUNT_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.SYNCHRONIZING_ACCOUNT);
            state.isError = [...state.isError, ActionTypes.SYNCHRONIZE_ACCOUNT_FAILURE];
            return { ...state };

        case ActionTypes.FETCH_LASTSYNCH_ACCOUNT_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.FETCH_LASTSYNCH_ACCOUNT_FAILURE);

            if (!state.isLoading.includes(Loadings.FETCHING_LASTSYNCH_ACCOUNT)) {
                state.isLoading.push(Loadings.FETCHING_LASTSYNCH_ACCOUNT);
            }
            return { ...state };

        case ActionTypes.FETCH_LASTSYNCH_ACCOUNT_SUCCESS:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_LASTSYNCH_ACCOUNT);
            // let account = state.accounts.find(account => account.customerAccountId === action.data.customerAccountId);
            // account.lastSynch = action.data;

            state.accounts = state.accounts.map(account => {
                if (account.customerAccountId === action.data.customerAccountId) {
                    account.lastSynch = action.data;
                    return account;
                }
                return account;
            });

            return { ...state };

        case ActionTypes.FETCH_LASTSYNCH_ACCOUNT_FAILURE:
            state.isLoading = state.isLoading.filter(s => s !== Loadings.FETCHING_LASTSYNCH_ACCOUNT);
            state.isError = [...state.isError, ActionTypes.FETCH_LASTSYNCH_ACCOUNT_FAILURE];
            return { ...state };


        case ActionTypes.SEND_MFA_CODE_REQUEST:
            state.isError = state.isError.filter(e => e !== ActionTypes.SEND_MFA_CODE_FAILURE);

            if (!state.isLoading.includes(Loadings.SENDING_MFA_CODE)) {
                state.isLoading.push(Loadings.SENDING_MFA_CODE);
            }
            return { ...state };

        case ActionTypes.SEND_MFA_CODE_SUCCESS:

            state.isLoading = state.isLoading.filter(l => l !== Loadings.SENDING_MFA_CODE);

            let accountData = state.accounts.find(account => account.customerAccountId === action.data.customerAccountId);
            accountData.additionalAuthenticationData = action.data;

            return { ...state };

        case ActionTypes.SEND_MFA_CODE_FAILURE:
            state.isLoading = state.isLoading.filter(l => l !== Loadings.SENDING_MFA_CODE);
            state.isError = [...state.isError, ActionTypes.SEND_MFA_CODE_FAILURE];
            return { ...state };

        default:
            return state;
    }
};
export default AuthReducer;