import React from 'react';



// Components
import { AgentDetailsDefaultAccountView } from './agent_details-default_account-view';
import { AgentDetailsDefaultAccountTab } from './agent_details-default_account-tab';
import { AgentDetailsDefaultAccountViewForm } from './agent_details-default_account-view/agent_details-default_account-view-form';


export const AgentDetailsDefaultAccount = ({ selectedAccount, agent, setMfaCode, agentAccounts, hasAccounts, setSelectedAccount, setAgentAccounts, newAccount, setShowDetail, accountState, featureState, customizationState }) => {

    const agentId = agent.id;
    const accountId = selectedAccount.customerAccountId;
    //const {agentId, accountId } =  useParams()


    return (
        hasAccounts ?
            <React.Fragment>
                <AgentDetailsDefaultAccountTab agentId={agentId} accountId={accountId} featureState={featureState} customizationState={customizationState} />
                <AgentDetailsDefaultAccountView featureState={featureState} customizationState={customizationState} accountState={accountState} selectedAccount={selectedAccount} agent={agent} setMfaCode={setMfaCode} agentAccounts={agentAccounts} hasAccounts={hasAccounts} setSelectedAccount={setSelectedAccount} setAgentAccounts={setAgentAccounts} newAccount={newAccount} />
            </React.Fragment>
            :
            <AgentDetailsDefaultAccountViewForm accountState={accountState} agent={agent} selectedAccount={selectedAccount} agentAccounts={agentAccounts} hasAccounts={hasAccounts} setSelectedAccount={setSelectedAccount} setAgentAccounts={setAgentAccounts} setMfaCode={setMfaCode} newAccount={newAccount} setShowDetail={setShowDetail} />
    );
};

