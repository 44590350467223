import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import _ from '../../../../constants/i18n/engine';
import T9n from '../../../../constants/i18n/translations';

import { deleteAccount } from '../../../../actions/auth-api';

export const AgentDetailsDefaultModalDelete = ({ selectedAccount, agentAccounts, agent }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { userId } = useParams();
    // const hideModal = () => {
    // 	setTimeout(() => {
    // 		$('.modal').toggle();
    // 	}, 1000);
    // };

    // const [deleteAttempt, setDeleteAttempt] = useState(false);




    return (
        <div className="modal modal-auto fade z-max" id="deleteModal" tabIndex={-1} aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered border-none">
                <div className="modal-content border-none rounded-1">
                    <div className="modal-body border-0 p-5">
                        <h5 className="sr-only visually-hidden" id="deleteModalLabel">Delete Modal</h5>
                        <i role="button" data-bs-dismiss="modal" aria-label="Close" className="user-select-none icns text-danger-hover bi bi-x fs-3 lh-1 float-right position-absolute top-0 end-0 p-4 z-2"></i>
                        <div className="block">
                            <div className="d-inline-block p-3 bg-danger-subtle text-danger border-none rounded-2">
                                <i className="icns bi bi-folder-x fs-1 lh-1"></i>
                            </div>
                            <h6 className="h3 ff-aux ls-0 fw-medium ls-n1 mt-3 text-danger" id="addNew">{_(T9n.delete_account)}</h6>
                        </div>
                        <div className="block mb-4">
                            <div className="h5 lh-copy">{_(T9n.delete_question)}<strong className="ff-aux">'{selectedAccount?.name}'?</strong></div>
                            <div className="mb-0 small text-body-88">{_(T9n.delete_information)}</div>
                        </div>
                        <div className="block border-0">
                            <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => {
                                // e.preventDefault();

                                const accounts = agentAccounts.filter(a => a.customerAccountId !== selectedAccount.customerAccountId);
                                const defaultAccountId = accounts.length > 0 ? accounts[accounts.length - 1].customerAccountId : "new-account";

                                dispatch(deleteAccount(selectedAccount.customerAccountId));
                                history.push(`/u/${userId}/agents/${agent.id}/${defaultAccountId}`);

                            }}>
                                {_(T9n.delete_confirmation)}
                            </button>
                            <button type="button" className="btn text-body-88 text-body-hover" data-bs-dismiss="modal">{_(T9n.cancel)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};