import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as R from 'ramda';

import { _, dateToDelta } from '../../../../constants/i18n/engine';
import T9n from '../../../../constants/i18n/translations';
import ClientJWT from '../../../../constants/webapi/auth';
import * as Loadings from '../../../../constants/loadings/auth';


export const AgentListBodyItem = ({ accounts, agent, searchTerm, newOrder, setShowDetail, showDetail, accountState }) => {
    const [lastSync, setlastSync] = useState(undefined);
    const [syncErrors, setSyncErrors] = useState(undefined);

    const { agentId, userId } = useParams();

    let currentAgentAccounts = undefined;
    let accountLength = 0;
    let hasAccounts = accountLength > 0;
    let lastAccount = undefined;
    let lastAccountId = 'new-account';


    if (!R.isNil(accounts) && !R.isEmpty(accounts)) {
        currentAgentAccounts = accounts.filter(a => a.agentId === agent.id);
        accountLength = currentAgentAccounts.length;
        hasAccounts = accountLength > 0;
        lastAccount = currentAgentAccounts[currentAgentAccounts.length - 1];
        lastAccountId = lastAccount ? lastAccount.customerAccountId : 'new-account';
    }

    useEffect(() => {

        //prevent new requests when agentDetails is displayed on screen
        if (showDetail && agentId) return;
        if (accountState.isLoading.includes(Loadings.FETCHING_LASTSYNCH_ACCOUNT)) return;

        try {

            if (hasAccounts) {

                //will cancel the promise/prevent state update when agent isn't rendered
                let cancelPromise = false;
                const client = new ClientJWT().getClient();
                let promiseAccounts = [];

                for (const account of currentAgentAccounts) {
                    promiseAccounts.push(new Promise((resolve, reject) => {

                        client.getLastSynchronizationByAccount(account.customerAccountId, (error, response) => {
                            if (error) reject(error);
                            else resolve(response);
                        });
                    }));
                }

                const abortController = new AbortController();

                Promise.all(promiseAccounts).then((accounts) => {

                    if (cancelPromise) return;

                    const syncAccountsErrors = accounts.filter((account) => (account.synchronizationStateDetails === 6 || account.synchronizationStateDetails === 5 || account.synchronizationStateDetails === 12));
                    const syncAccountsErrorsAmount = syncAccountsErrors.length > 0 ? syncAccountsErrors.length : undefined;

                    const lastDate = new Date(Math.max(...accounts.map(account => new Date(account.creationDate))));

                    setSyncErrors(syncAccountsErrorsAmount);
                    setlastSync(dateToDelta(lastDate));

                });

                return () => {
                    // setSyncErrors(undefined);
                    // setlastSync(undefined);
                    cancelPromise = true;
                    return abortController.abort();
                };

            }

        } catch (error) {

            //console.log(error.message);
        }


        // eslint-disable-next-line
    }, [agent, searchTerm, newOrder, showDetail]);


    /* hasAccounts && mostRecentLastSynch ? _(T9n.last_sync) : (hasAccounts ? _(T9n.last_sync) : "") */
    /*hasAccounts && mostRecentLastSynch ? dateToDelta(mostRecentLastSynch) : (hasAccounts ? "——" : "")*/

    return (
        <li className={"list-group-item supplier-item" + (hasAccounts ? " active" : "")}>
            <div className="row align-items-center gx-2">
                <div className="col-10 col-md-9 col-lg">
                    <div className="row align-items-center gx-3">
                        <div className="col-auto">
                            <div className="supplier-agent square">
                                <img className="m-auto img-fluid" src={agent.name.toLowerCase() === 'impotsv2' ? "https://az859819.vo.msecnd.net/agents/logos/medium/impots.png" : agent.logos?.medium.url || "https://az859819.vo.msecnd.net/agents/logos/medium/unknown.png"} alt={agent.displayName.replace(/([a-z])([A-Z])/g, '$1 $2') + " logo"} height="48" width="48" />
                                {/* <div className={"agents-small m-auto " + agent.name.toLowerCase()} aria-hidden="true"></div> */}
                            </div>
                        </div>
                        <div className="col supplier-name">
                            <div className="row align-items-center mt-auto gx-1">
                                <div className={"col-auto" + (hasAccounts || agent.agentCurrentState ? " col-md-8 col-lg" : "")}>
                                    <h3 className="fs-root ff-aux mb-0 fw-demi text-truncate ls-n1 ls-md-none">{agent.displayName.replace(/([a-z])([A-Z])/g, '$1 $2')}</h3>
                                </div>
                                {hasAccounts || agent.agentCurrentState ?
                                    <React.Fragment>
                                        <div className="col-auto d-md-none text-center">
                                            <i className="icns bi bi-dot text-body-66 my-auto fs-root lh-1"></i>
                                        </div>
                                        <div className="col col-md-4 col-lg">
                                            {
                                                agent.agentCurrentState === 0 ?
                                                    <React.Fragment>
                                                        {!syncErrors && <p className="fs-sm lh-1 mb-0 text-truncate">
                                                            <span className="text-body-66 d-none d-sm-inline-block">{hasAccounts && lastSync ? _(T9n.last_sync) : (hasAccounts ? _(T9n.last_sync) : "")}</span>  <span className="text-body-88">{hasAccounts && lastSync ? lastSync : (hasAccounts ? (<span className="spinner-border text-primary spinner-border-sm mx-2" role="status"><span className="visually-hidden">Loading...</span></span>) : "")}</span>
                                                        </p>}
                                                        {syncErrors && <p className="fs-sm lh-1 mb-0 text-truncate"> <span className="text-body-66 d-none d-sm-inline-block align-middle">{hasAccounts && lastSync ? _(T9n.last_sync) : (hasAccounts ? _(T9n.last_sync) : "")}</span> <i className="icns bi bi-exclamation-triangle-fill me-1 text-danger align-middle"></i><span className="text-danger d-none d-sm-inline-block align-middle">{syncErrors > 1 ? `${syncErrors} ${_(T9n.Errors)}` : `${syncErrors} ${_(T9n.Error)}`} </span></p>}
                                                    </React.Fragment>
                                                    :
                                                    agent.agentCurrentState === 1 ?
                                                        <p className="badge btn-maintenance rounded-pill fw-demi py-1 px-2 fs-xs lh-1 me-xs overline my-auto">{_(T9n.inMaintenance)}</p>
                                                        :
                                                        <p className="badge btn-error rounded-pill fw-demi py-1 px-2 fs-xs lh-1 me-xs overline my-auto">{_(T9n.unavailable)}</p>
                                            }
                                        </div>
                                    </React.Fragment>
                                    :
                                    ""
                                }
                            </div>
                            <div className="row align-items-center mb-auto mt-1 mt-lg-auto gx-1">
                                <div className={"col-auto" + (hasAccounts ? " col-md-8 col-lg" : "")}>
                                    <p className="fs-md text-body-88 text-truncate my-auto">{agent.category}</p>
                                </div>

                                {hasAccounts ?
                                    <React.Fragment>
                                        <div className="col-auto d-md-none text-center">
                                            <i className="icns bi bi-dot text-body-66 my-auto fs-root lh-1"></i>
                                        </div>
                                        <div className="col-auto col-md-4 col-lg">
                                            <p className="block lh-1 fs-aux text-body-88 mt-auto mb-0 text-truncate ls-n1 ls-md-none">{accountLength + " " + (accountLength > 1 ? _(T9n.accounts) : _(T9n.account))}</p>
                                        </div>
                                    </React.Fragment>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-lg-auto text-end justify-content-end" >
                    {/* <Link to={`/agents/${agent.id}/${lastAccountId}`} className={"btn fw-medium" + (hasAccounts ? " p-0 my-auto text-body-66 text-body-hover" : " lh-base btn-add my-auto p-1")} type="button" onClick={() => setShowDetail(true)} > */}
                    {hasAccounts ?
                        <Link to={`/u/${userId}/agents/${agent.id}/${lastAccountId}`} className="btn fw-medium p-1 my-auto text-body-66 text-body-hover text-primary-focus border-none" onClick={() => setShowDetail(true)}>
                            <span className="mx-sm-2 fs-sm d-none d-md-inline-block">{_(T9n.check_details)}</span>
                            <i className="icns bi bi-chevron-right fs-5 lh-1"></i>
                        </Link>
                        :
                        <Link to={`/u/${userId}/agents/${agent.id}/${lastAccountId}`} className="btn fw-medium lh-base btn-add my-auto p-1" onClick={() => setShowDetail(true)}>
                            <i className="icns bi bi-plus fs-5 lh-1"></i>
                            <span className="mx-sm-2 fs-sm d-none d-md-inline-block">{_(T9n.add)}</span>
                        </Link>
                    }
                </div>
            </div>
        </li>
    );
};
