import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as R from 'ramda';
import { useHistory, useParams } from "react-router-dom";

import Account from '../../../../constants/models/account';
import Credential from '../../../../constants/models/credential';
import { synchIsRunning } from "../../../../constants/utils";

// Common
import { AgentDetailsDefaultModalDelete } from './../_common/agent_details-default_modal-delete';
import { AgentDetailsDefaultModalMFA } from './../_common/agent_details-default_modal-MFA';

// Components
import { AgentDetailsDefaultHeader } from './agent_details-default_header';
import { AgentDetailsDefaultSideNav } from './agent_details-default_sideNav';
import { AgentDetailsDefaultAccount } from './agent_details-default_account';
import { AgentDetailsDefaultReport } from './agent_details-default_report';



export const AgentDetailsDefault = ({ agent, accountState, featureState, customizationState, setShowDetail }) => {
    const { userId, accountId } = useParams();
    const history = useHistory();
    const accounts = (!R.isNil(accountState.accounts) && !R.isEmpty(accountState.accounts)) ? accountState.accounts.filter(account => account.agentId === agent.id) : [];
    const account = (!R.isNil(accountState.accounts) && !R.isEmpty(accountState.accounts)) ? accountState.accounts.find(account => account.customerAccountId === accountId) : [];

    const newAccount = (
        new Account({
            id: "",
            customerAccountId: "",
            customerUserId: "sbx",
            name: "",
            agentId: agent.id,
            credentials: agent.fields.map((field, i) => {
                return new Credential({
                    position: i,
                    value: "",
                    alg: "none",
                });
            }),
            mode: "",
            additionalAuthenticationData: ""
        })
    );


    const [agentAccounts, setAgentAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [mfaCode, setMfaCode] = useState("");
    const [lastSynch, setLastSynch] = useState(selectedAccount?.lastSynch);

    let hasAccounts = agentAccounts.length > 0 && selectedAccount?.customerAccountId !== "";
    let hasNewAccount = agentAccounts.find(a => a.customerAccountId === "") ? true : false;
    let isRunning = false;


    useLayoutEffect(() => {
        if (R.isEmpty(accounts) || R.isNil(accounts)) {

            history.push(`/u/${userId}/agents/${agent.id}/new-account`);

        } else if ((accountId === undefined || !R.isNil(accountId)) && R.isNil(account) && accountId !== "new-account") {

            history.push(`/u/${userId}/agents`);

        }

        // eslint-disable-next-line	
    }, []);


    useEffect(() => {

        if (R.isEmpty(accounts) || R.isNil(accounts)) {

            setAgentAccounts([{ ...newAccount }]);
            setSelectedAccount({ ...newAccount });

        } else {

            setAgentAccounts(accounts);

        }

        // eslint-disable-next-line		
    }, [agent]);


    useEffect(() => {

        if (accountId === "new-account") {

            setSelectedAccount({ ...newAccount });
            setAgentAccounts([...accounts, newAccount]);

        } else if (!R.isNil(account) && accounts.length > 0) {

            setSelectedAccount(account);
            setAgentAccounts(accounts);

        } else if (!R.isNil(account)) {

            setSelectedAccount(accounts[accounts.length - 1]);
            setAgentAccounts(accounts);

        } else if (R.isNil(account)) {

            setAgentAccounts(accounts);
            setSelectedAccount(accounts[accounts.length - 1]);

        }

        // eslint-disable-next-line	
    }, [accountId, accountState]);


    useEffect(() => {
        let lastSynchItem = selectedAccount?.lastSynch;

        if (selectedAccount?.customerAccountId !== "" && R.isNil(lastSynchItem)) {

            setLastSynch(lastSynchItem);

        } else {

            setLastSynch(lastSynchItem);
        }

        // eslint-disable-next-line	
    }, [selectedAccount?.lastSynch]);


    if (!R.isNil(lastSynch)) {

        if (synchIsRunning(lastSynch.synchronizationState)) {

            isRunning = true;

        } else if (lastSynch.synchronizationStateDetails === 26) {

            isRunning = false;
        }
    };


    const monitorRunningSynch = (lastSynch) => {

        if (synchIsRunning(lastSynch.synchronizationState)) {

            isRunning = true;

        } else if (lastSynch.synchronizationStateDetails === 26) {

            isRunning = false;

        }
    };


    if (isRunning) {
        setTimeout(monitorRunningSynch(lastSynch), 2500);
    };


    return (
        <React.Fragment>
            <article className="supplier bg-body-tertiary flex-column h-100 gap-g2 p-2" id={agent.displayName}>
                <h1 className="sr-only visually-hidden">{agent.displayName}</h1>
                {
                    R.isEmpty(agentAccounts) || R.isNil(selectedAccount) ?
                        <div className="flex-column h-100 align-itemns-center bg-body-tertiary mt-4">
                            <div className="container container-narrow text-center my-auto px-xl-5">
                                <div className="list-group-item supplier-item progress rounded-pill border-none mb-2 py-0" >
                                    <div className="progress-bar progress-bar-striped w-100 progress-bar-animated bg-primary" role="progressbar"></div>
                                </div>
                            </div>
                        </div> :
                        <React.Fragment>
                            <AgentDetailsDefaultHeader agent={agent} accountState={accountState} agentAccounts={agentAccounts} hasAccounts={hasAccounts} accounts={accounts} setShowDetail={setShowDetail} />
                            <section className="supplier-accounts flex-column flex-grow-1 px-md-3 bg-body-tertiary z-0 h-inherit scroll-auto">
                                <h1 className="sr-only visually-hidden">Agent - accounts</h1>
                                <div className="row g-2 pt-3 h-md-inherit">
                                    <div className="col-12 col-md-3 col-xl-2 h-md-inherit">
                                        <AgentDetailsDefaultSideNav agent={agent} agentAccounts={agentAccounts} selectedAccount={selectedAccount} hasNewAccount={hasNewAccount} hasAccounts={hasAccounts} />
                                    </div>
                                    <div className="col-12 col-md h-md-inherit scroll-md-auto overflow-lg-hidden">
                                        <div className="row g-3 h-md-inherit">
                                            <div className="col-lg flex-column flex-grow-1 col-12 col-sm h-lg-inherit scroll-lg-auto">
                                                <AgentDetailsDefaultAccount featureState={featureState} customizationState={customizationState} accountState={accountState} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} agent={agent} setMfaCode={setMfaCode} agentAccounts={agentAccounts} hasAccounts={hasAccounts} setAgentAccounts={setAgentAccounts} newAccount={newAccount} setShowDetail={setShowDetail} />
                                            </div>
                                            <div className="col-lg-4 order-first order-lg-last h-lg-inherit scroll-lg-auto">
                                                <AgentDetailsDefaultReport selectedAccount={selectedAccount} agent={agent} lastSynch={lastSynch} mfaCode={mfaCode} setMfaCode={setMfaCode} isRunning={isRunning} hasAccounts={hasAccounts} newAccount={newAccount} accountState={accountState} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                }
            </article>
            <AgentDetailsDefaultModalDelete selectedAccount={selectedAccount} agentAccounts={agentAccounts} agent={agent} />
            <AgentDetailsDefaultModalMFA selectedAccount={selectedAccount} mfaCode={mfaCode} setMfaCode={setMfaCode} isRunning={isRunning} />
        </React.Fragment>
    );
};


