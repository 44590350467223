import React from 'react';
import * as R from 'ramda';
import { NavLink, useParams } from "react-router-dom";
import _ from 'src/constants/i18n/engine';
import T9n from 'src/constants/i18n/translations';

export const AgentDetailsDefaultAccountTab = ({ agentId, accountId, featureState, customizationState }) => {

    const { userId } = useParams();
    const isFilesDisabled = R.isNil(featureState.feature) || !featureState.feature.activateDisplayDocuments;
    return (
        <div className="container-fluid">
            <nav className="nav nav-tabs border-bottom border-body mb-4">
                <h1 className="sr-only visually-hidden">Navigation</h1>
                <NavLink exact to={`/u/${userId}/agents/${agentId}/${accountId}`} activeClassName="bg-body" className="nav-link border-none fs-aux text-inherit" role="presentation">
                    <i className="icns bi bi-sliders lh-1 me-2 align-middle"></i>
                    {_(T9n.parameters)}
                </NavLink>
                {/* <NavLink to={`/u/${userId}/agents/${agentId}/${accountId}/files`} activeClassName="bg-body" className={"nav-link border-none fs-aux text-inherit text-body-88" + (isFilesDisabled ? ' disabled' : '')} role="presentation">
                    <i className="icns bi bi-archive lh-1 me-2 align-middle"></i>
                    Fichiers
                </NavLink> */}
                <NavLink to={`/u/${userId}/agents/${agentId}/${accountId}/delete`} activeClassName="bg-body" className="nav-link border-none fs-aux text-inherit ms-auto" role="presentation">
                    <i className="icns bi bi-trash lh-1 me-2 align-middle fs-root"></i>
                    {_(T9n.delete)}
                </NavLink>
            </nav>
        </div>
    );
};
