import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as R from 'ramda';

import { _, dateToDelta } from '../../../../constants/i18n/engine';
import T9n from '../../../../constants/i18n/translations';


export const AgentDetailsDefaultHeader = ({ agent, accountState, agentAccounts, hasAccounts, accounts, setShowDetail }) => {

    const history = useHistory();
    const { userId } = useParams();
    const [lastSync, setlastSync] = useState(undefined);
    const [syncErrors, setSyncErrors] = useState(undefined);
    let accountLength = agentAccounts.length;


    useEffect(() => {

        if (accounts && accounts.every(account => account.lastSynch)) {

            const syncAccountsErrors = accounts.filter((account) => (account.lastSynch.synchronizationStateDetails === 6 || account.lastSynch.synchronizationStateDetails === 5 || account.lastSynch.synchronizationStateDetails === 12));
            const syncAccountsErrorsAmount = syncAccountsErrors.length > 0 ? syncAccountsErrors.length : undefined;

            const lastDate = new Date(Math.max(...accounts.map(account => new Date(account.lastSynch.creationDate))));

            setSyncErrors(syncAccountsErrorsAmount);
            setlastSync(dateToDelta(lastDate));
        }

        // eslint-disable-next-line	
    }, [accountState]);


    return (
        !R.isNil(agent) ?

            <header className="supplier-header card shadow-xl flex-grow-0 card p-3 sticky-sm-top">
                <h1 className="sr-only visually-hidden">Agent - {agent.displayName}</h1>
                <div className="container-fluid d-block">
                    <div className="row align-items-center">
                        <div className="col-auto col-md col-xl-9">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="row align-items-center gx-0">
                                        <div className="col">
                                            <button type="button" className="btn p-1 text-primary-hover o-75 o-100-hover" onClick={() => { setShowDetail(false); setTimeout(() => history.push(`/u/${userId}/agents`), 450); }} >
                                                <i className="bi bi-chevron-left fs-5 mx-1"></i>
                                                <p className="sr-only visually-hidden">Return</p>
                                            </button>
                                        </div>
                                        <div className="col">
                                            <div className="supplier-agent square">
                                                <img className="badge-agent m-auto img-fluid" src={agent.name.toLowerCase() === 'impotsv2' ? "https://az859819.vo.msecnd.net/agents/logos/medium/impots.png" : agent.logos?.medium.url || "https://az859819.vo.msecnd.net/agents/logos/medium/unknown.png"} alt={agent.displayName.replace(/([a-z])([A-Z])/g, '$1 $2') + " logo"} height="48" width="83" />
                                                {/* <div className={"agents-small m-auto " + agent.name.toLowerCase()} aria-hidden="true"></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col supplier-name">
                                    <div className="row align-items-center mt-auto gx-2">
                                        <div className={"col-auto" + (hasAccounts || agent.agentCurrentState ? " col-xl-2" : "")}>
                                            <div className="fs-root ff-aux mb-0 fw-demi text-truncate ls-n1 ls-md-none">{agent.displayName.replace(/([a-z])([A-Z])/g, '$1 $2')}</div>
                                        </div>

                                        {hasAccounts || agent.agentCurrentState ?
                                            <React.Fragment>
                                                {/* <div className="col-auto d-lg-none text-center">
                                                    <i className="icns bi bi-dot text-body-66 my-auto fs-root lh-1"></i>
                                                </div> */}
                                                <div className="col col-xl-10">
                                                    {
                                                        agent.agentCurrentState === 0 ?
                                                            <React.Fragment>
                                                                {!syncErrors && <p className="fs-sm lh-1 mb-0 text-truncate"><span className="o-75 d-none d-sm-inline-block">{hasAccounts && lastSync ? _(T9n.last_sync) : (hasAccounts ? _(T9n.last_sync) : "")}</span>  <span className="text-body-88">{hasAccounts && lastSync ? lastSync : (hasAccounts ? (<span className="spinner-border text-primary spinner-border-sm mx-2" role="status"><span className="visually-hidden">Loading...</span></span>) : "")}</span> </p>}
                                                                {syncErrors && <p className="fs-sm lh-1 mb-0 text-truncate"> <span className="o-75 d-none d-sm-inline-block align-middle">{hasAccounts && lastSync ? _(T9n.last_sync) : (hasAccounts ? _(T9n.last_sync) : "")}</span> <i className="icns bi bi-exclamation-triangle-fill me-1 text-danger align-middle"></i><span className="text-danger d-none d-sm-inline-block align-middle">{syncErrors > 1 ? `${syncErrors} ${_(T9n.Errors)}` : `${syncErrors} ${_(T9n.Error)}`} </span></p>}
                                                            </React.Fragment>
                                                            :
                                                            agent.agentCurrentState === 1 ?
                                                                <p className="badge btn-maintenance rounded-pill fw-demi py-1 px-2 fs-xs lh-1 me-xs overline my-auto">{_(T9n.inMaintenance)}</p>
                                                                :
                                                                <p className="badge btn-error rounded-pill fw-demi py-1 px-2 fs-xs lh-1 me-xs overline my-auto">{_(T9n.unavailable)}</p>
                                                    }
                                                </div>
                                            </React.Fragment>
                                            :
                                            ""
                                        }
                                    </div>


                                    <div className="row align-items-center mb-auto mt-1 mt-lg-auto gx-2">
                                        <div className={"col-auto" + (hasAccounts ? " col-xl-2" : "")}>
                                            <div className="flex-row align-items-center">
                                                <p className="fs-md text-body-88 text-truncate my-auto">{agent.category}</p>
                                                <i className="icns bi bi-dot text-body-66 my-auto fs-root lh-1"></i>
                                                <a href={agent.url} target="_blank" rel="noreferrer" className="icns" title="open agent in new window"><i className="bi bi-box-arrow-in-up-right lh-1 align-middle"></i></a>
                                            </div>
                                        </div>
                                        {hasAccounts ?
                                            <React.Fragment>
                                                {/* <div className="col-auto d-lg-none text-center">
                                                    <i className="icns bi bi-dot text-body-66 my-auto fs-root lh-1"></i>
                                                </div> */}
                                                <div className="col col-xl-10">
                                                    <p className="block lh-1 fs-aux text-body-88 mt-auto mb-0 text-truncate ls-n1 ls-md-none fw-medium">{accountLength + " " + (accountLength > 1 ? _(T9n.accounts) : _(T9n.account))}</p>
                                                </div>
                                            </React.Fragment>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col text-end justify-content-end">
                            {agent.isMFA ?
                                <button type="button" className="btn rounded-pill fs-md lh-1 mb-0 btn-primary" data-toggle="tip">
                                    MFA
                                    <div tip-data="info" tip-placement="bottom center" className="rounded-1 white text-center pa-2">{_(T9n.MFAExplanation)}</div>
                                </button>
                                :
                                ''
                            }

                            {/* <button className="btn text-body-88 text-body-hover text-body-hover p-1 ms-2" type="button"><i className="icns bi bi-life-preserver fs-6 lh-1 text-info"></i><span className="d-none d-md-inline-block align-middle mx-md-2">{_(T9n.help)}</span></button> */}
                        </div>

                    </div>
                </div>
            </header>
            :
            ""
    );

};




