export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';

export const ADD_ACCOUNT_REQUEST = 'ADD_ACCOUNT_REQUEST';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';
export const ADD_ACCOUNT_UNAVAILABLE_NAME = 'ADD_ACCOUNT_UNAVAILABLE_NAME';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const FETCH_ACCOUNT_DOCUMENTS_REQUEST = 'FETCH_ACCOUNT_DOCUMENTS_REQUEST';
export const FETCH_ACCOUNT_DOCUMENTS_SUCCESS = 'FETCH_ACCOUNT_DOCUMENTS_SUCCESS';
export const FETCH_ACCOUNT_DOCUMENTS_FAILURE = 'FETCH_ACCOUNT_DOCUMENTS_FAILURE';

export const FETCH_ACCOUNT_DOCUMENT_REQUEST = 'FETCH_ACCOUNT_DOCUMENT_REQUEST';
export const FETCH_ACCOUNT_DOCUMENT_SUCCESS = 'FETCH_ACCOUNT_DOCUMENT_SUCCESS';
export const FETCH_ACCOUNT_DOCUMENT_FAILURE = 'FETCH_ACCOUNT_DOCUMENT_FAILURE';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const SYNCHRONIZE_ACCOUNT_REQUEST = 'SYNCHRONIZE_ACCOUNT_REQUEST';
export const SYNCHRONIZE_ACCOUNT_SUCCESS = 'SYNCHRONIZE_ACCOUNT_SUCCESS';
export const SYNCHRONIZE_ACCOUNT_FAILURE = 'SYNCHRONIZE_ACCOUNT_FAILURE';

export const FETCH_LASTSYNCH_ACCOUNT_REQUEST = 'FETCH_LASTSYNCH_ACCOUNT_REQUEST';
export const FETCH_LASTSYNCH_ACCOUNT_SUCCESS = 'FETCH_LASTSYNCH_ACCOUNT_SUCCESS';
export const FETCH_LASTSYNCH_ACCOUNT_FAILURE = 'FETCH_LASTSYNCH_ACCOUNT_FAILURE';

export const FETCH_MFA_DATA_REQUEST = 'FETCH_MFA_DATA_REQUEST';
export const FETCH_MFA_DATA_SUCCESS = 'FETCH_MFA_DATA_SUCCESS';
export const FETCH_MFA_DATA_FAILURE = 'FETCH_MFA_DATA_FAILURE';

export const SEND_MFA_CODE_REQUEST = 'SEND_MFA_CODE_REQUEST';
export const SEND_MFA_CODE_SUCCESS = 'SEND_MFA_CODE_SUCCESS';
export const SEND_MFA_CODE_FAILURE = 'SEND_MFA_CODE_FAILURE';

export const CLEAR_ACCOUNT_ERRORS = 'CLEAR_ACCOUNT_ERRORS';

export const PERMISSION_DENIED = 'PERMISSION_DENIED';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';