import axios from 'axios';
import * as ActionTypes from '../constants/actiontypes/auth';
import ClientJWT from '../constants/webapi/auth';
import * as urls from '../constants/webapi/storage';


export function clearAccountErrors() {
    return {
        type: ActionTypes.CLEAR_ACCOUNT_ERRORS,
    };
}
export function unavailableAccountName() {
    return {
        type: ActionTypes.ADD_ACCOUNT_UNAVAILABLE_NAME,
    };
}

export async function RedirectIdentityProvider(id) {
    return new Promise((resolve, reject) => {
        return axios.get(urls.identityProviderUrl(id))
            .then(response => {
                sessionStorage.setItem('signOutUrl', response.data.SignOutUrl);
                sessionStorage.setItem('signInUrl', response.data.SignInUrl);
                window.location = response.data.SignInUrl;
                resolve();
            })
            .catch((error) => {

                reject();
            });
    });
};

export async function CodeAuthentication(code, state) {
    return new Promise((resolve, reject) => {
        return axios.post(urls.identityProviderCodeUrl(), { code, state })
            .then(response => {
                sessionStorage.setItem("access_token", response.data.AccessToken);
                resolve();
            })
            .catch((error) => {
                console.log(error);
                // if (error.response.status === 401) {
                //     window.location = sessionStorage.getItem('signInUrl');
                // }
                reject();
            });
    });
};

export function getUsers() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_USERS_REQUEST });
        return axios.get(urls.getUsersUrl())
            .then((response) => {
                dispatch({
                    type: ActionTypes.FETCH_USERS_SUCCESS,
                    data: response.data
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: ActionTypes.FETCH_USERS_FAILURE,
                    response: error.response
                });
            });
    };
}

export function getUser(userId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_USER_REQUEST });
        return axios.get(urls.getUserUrl(userId))
            .then((response) => {
                const token = response.data.accessToken;
                const environment = response.data.environment;
                sessionStorage.setItem('@cloudAgentsClientToken', token);
                sessionStorage.setItem('@cloudAgentsEnvironment', environment);

                dispatch({
                    type: ActionTypes.FETCH_USER_SUCCESS,
                    data: response.data
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: ActionTypes.FETCH_USER_FAILURE,
                    response: error.response
                });
                throw error;
            });
    };
}

export function getAccounts(agentId) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.getAllAccounts({ agentId: agentId }, (error, response) => {

                if (error) {
                    dispatch({
                        type: ActionTypes.FETCH_ACCOUNT_FAILURE,
                        error: error
                    });
                    return error;

                }

                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_SUCCESS,
                    data: response
                });

                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.FETCH_ACCOUNT_FAILURE,
                error: error.message
            });

        }

    };
}
export function getDocumentsByAccountId(accountId) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.getDocumentsByAccount({}, accountId, (error, response) => {

                if (error) {
                    dispatch({
                        type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE,
                        error: error
                    });
                    return error;

                }

                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_SUCCESS,
                    data: response
                });

                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE,
                error: error.message
            });

        }

    };
}


export function addAccount(account) {

    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({ type: ActionTypes.ADD_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.createAccount(account, (error, response) => {
                if (error) {

                    dispatch({
                        type: ActionTypes.ADD_ACCOUNT_FAILURE,
                        error: error
                    });

                    return reject(error);
                }

                dispatch({
                    type: ActionTypes.ADD_ACCOUNT_SUCCESS,
                    data: response
                });
                dispatch(getLastSynchronizationByAccountId(
                    response.customerAccountId
                ));


                return resolve(response);

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.ADD_ACCOUNT_FAILURE,
                error: error.message
            });

        }

    });
}

export function updateAccount(customerAccountId, account) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.UPDATE_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.modifyAccount(customerAccountId, account, (error, response) => {
                if (error) {

                    dispatch({
                        type: ActionTypes.UPDATE_ACCOUNT_FAILURE,
                        error: error
                    });
                    return error;
                }


                dispatch({
                    type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
                    data: response
                });
                dispatch(synchronizeAccountById(
                    response.customerAccountId
                ));
                dispatch(getLastSynchronizationByAccountId(
                    response.customerAccountId
                ));

                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.UPDATE_ACCOUNT_FAILURE,
                error: error.message
            });
        }


    };
}


export function deleteAccount(customerAccountId) {


    return (dispatch) => {
        dispatch({ type: ActionTypes.DELETE_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.deleteAccount(customerAccountId, (error, response) => {
                if (error) {

                    dispatch({
                        type: ActionTypes.DELETE_ACCOUNT_FAILURE,
                        error: error
                    });
                    return error;
                }

                dispatch({
                    type: ActionTypes.DELETE_ACCOUNT_SUCCESS,
                    customerAccountId: customerAccountId
                });
                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.DELETE_ACCOUNT_FAILURE,
                error: error.message
            });

        }


    };
}


export function synchronizeAccountById(accountId, customerUserId, isForced) {


    return (dispatch) => {
        dispatch({ type: ActionTypes.SYNCHRONIZE_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.synchronizeAccount(accountId, customerUserId, isForced, (error, response) => {

                if (error) {

                    dispatch({
                        type: ActionTypes.SYNCHRONIZE_ACCOUNT_FAILURE,
                        error: error
                    });
                    return error;
                }

                dispatch({
                    type: ActionTypes.SYNCHRONIZE_ACCOUNT_SUCCESS,
                    data: response
                });

                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.SYNCHRONIZE_ACCOUNT_FAILURE,
                error: error.message
            });

        }


    };
}

export function getLastSynchronizationByAccountId(accountId) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_LASTSYNCH_ACCOUNT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            var interval = setInterval(function () {
                client.getLastSynchronizationByAccount(accountId, (error, response) => {
                    if (error) {

                        dispatch({
                            type: ActionTypes.FETCH_LASTSYNCH_ACCOUNT_FAILURE,
                            error: error
                        });
                        clearInterval(interval);
                        return error;
                    }


                    if (response.synchronizationState === 5 ||
                        response.synchronizationState === 6 ||
                        response.synchronizationState === 7) {

                        clearInterval(interval);
                    }

                    dispatch({
                        type: ActionTypes.FETCH_LASTSYNCH_ACCOUNT_SUCCESS,
                        data: response
                    });
                    return response;

                });
            }, 1000 * 10);

        } catch (error) {

            dispatch({
                type: ActionTypes.FETCH_LASTSYNCH_ACCOUNT_FAILURE,
                error: error.message
            });

        }

    };
}


export function submitMfaCode(accountId, sbxSecretCode) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.SEND_MFA_CODE_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.sendMFACode(accountId, sbxSecretCode, (error, response) => {
                if (error) {

                    dispatch({
                        type: ActionTypes.SEND_MFA_CODE_FAILURE,
                        error: error
                    });
                    return error;
                }


                dispatch({
                    type: ActionTypes.SEND_MFA_CODE_SUCCESS,
                    data: response
                });
                dispatch(getLastSynchronizationByAccountId(
                    response.customerAccountId
                ));

                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.SEND_MFA_CODE_FAILURE,
                error: error.message
            });

        }


    };
}

//#region NEW VERSION
export function getDocuments(pageNumber = 1) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_REQUEST });
        return axios.get(urls.getDocumentsUrl(pageNumber))
            .then((response) => {
                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_SUCCESS,
                    data: response.data
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_DOCUMENTS_FAILURE,
                    error: error
                });
            });
    };
}

export function getDocumentById(documentId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_ACCOUNT_DOCUMENT_REQUEST });
        return axios.get(urls.getDocumentByIdUrl(documentId))
            .then((response) => {
                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_DOCUMENT_SUCCESS,
                    data: response
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: ActionTypes.FETCH_ACCOUNT_DOCUMENT_FAILURE,
                    error: error
                });
            });
    };
}

export function getDocumentContentById(documentId, documentName) {
    return axios.get(urls.getDocumentContentByIdUrl(documentId), { responseType: "arraybuffer" })
        .then((response) => {
            let link = document.createElement("a");
            link.style = "display: none";
            let file = new Blob([response.data], { type: response.headers["content-type"] });
            let fileurl = window.URL.createObjectURL(file);
            link.href = fileurl;
            link.target = "_blank";
            link.download = documentName;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

//#endregion