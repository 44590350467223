import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Switch, Route, useParams } from "react-router-dom";

// Localization
// import _ from '../../../../constants/i18n/engine';
// import T9n from '../../../../constants/i18n/translations';

// Components
import { AgentDetailsDefaultAccountViewForm } from './agent_details-default_account-view-form';
import { AgentDetailsDefaultAccountViewDelete } from './agent_details-default_account-view-delete';
import { AgentDetailsDefaultAccountViewFiles } from './agent_details-default_account-view-files';
import ClientJWT from 'src/constants/webapi/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsByAccountId } from 'src/actions/auth-api';



export const AgentDetailsDefaultAccountView = ({ selectedAccount, setSelectedAccount, hasAccounts, agent, setMfaCode, agentAccounts, setAgentAccounts, setShowDetail, accountState, featureState, customizationState }) => {
    const { userId } = useParams();
    return (
        <Switch>
            <Route exact path={`/u/:userId/agents/:agentId/:accountId`} >
                <AgentDetailsDefaultAccountViewForm customizationState={customizationState} accountState={accountState} agent={agent} selectedAccount={selectedAccount} agentAccounts={agentAccounts} hasAccounts={hasAccounts} setSelectedAccount={setSelectedAccount} setAgentAccounts={setAgentAccounts} setMfaCode={setMfaCode} setShowDetail={setShowDetail} />
            </Route>
            {hasAccounts &&
                <Switch>
                    {/* {
                        !R.isNil(featureState.feature) && featureState.feature.activateDisplayDocuments ?
                            <Route exact path={`/u/${userId}/agents/${agent.id}/${selectedAccount.customerAccountId}/files`} >
                                <AgentDetailsDefaultAccountViewFiles accountId={selectedAccount.customerAccountId} documents={accountState.documents} isLoading={accountState.isLoading} />
                            </Route>
                            : null
                    } */}
                    <Route exact path={`/u/:userId/agents/:agentId/:accountId/delete`} component={AgentDetailsDefaultAccountViewDelete} />
                </Switch>

            }
            {/* <Route render={() => {
				return <Redirect exact to={`/agents/${agent.id}/${selectedAccount.customerAccountId}`} />;
			}} /> */}
        </Switch>
    );
};
