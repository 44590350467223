import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { TAB, SORT_BY_ORDER } from '../../../constants/utils';


// Components
import { AgentListTabs } from './agent_list-tabs';
import { AgentListBody } from './agent_list-body';



export const AgentList = ({ searchTerm, agents, accounts, setShowDetail, showDetail, setInitialAgent, initialAgent, accountState }) => {

    // const agentState = useSelector(state => state.agents);
    // const accountSate = useSelector(state => state.auth);
    // const [searchTerm, setSearchTerm] = useState("");
    // const [favoriteAgents, setFavoriteAgents] = useState([]);
    const [searchFilterListAgents, setSearchFilterListAgents] = useState([]);
    // const [sortByOrder, setSortByOrder] = useState([]);
    // const [selectAgent, setSelectAgent] = useState([]);
    const [newOrder, setNewOrder] = useState(SORT_BY_ORDER.ASCENDING);
    const [tab, setTab] = useState(!R.isNil(accounts) && !R.isEmpty(accounts) ? TAB.MY_ACCOUNTS : TAB.ALL_AGENTS);

    useEffect(() => {
        if (!R.isNil(accounts) && !R.isEmpty(accounts)) {
            setTab(TAB.MY_ACCOUNTS);
        } else {
            setTab(TAB.ALL_AGENTS);
        }
    }, [accounts]);



    const switchSort = () => {
        if (newOrder === SORT_BY_ORDER.UNDEFINED) {
            setNewOrder(SORT_BY_ORDER.DESCENDING);
        }
        else if (newOrder === SORT_BY_ORDER.ASCENDING) {
            setNewOrder(SORT_BY_ORDER.DESCENDING);
        }
        else {
            setNewOrder(SORT_BY_ORDER.ASCENDING);
        }

    };

    useEffect(() => {
        const modalBackdrop = document.querySelector('.modal-backdrop.show');
        const body = document.querySelector('body');
        body.style.setProperty('overflow', 'visible');
        modalBackdrop && modalBackdrop.remove();
    }, []);


    return (
        <React.Fragment>
            <h1 className="sr-only visually-hidden">SBX Webview - Agents</h1>
            <AgentListTabs switchSort={switchSort} tab={tab} setTab={setTab} agents={agents} accounts={accounts} searchFilterListAgents={searchFilterListAgents} newOrder={newOrder} setNewOrder={setNewOrder} />
            <AgentListBody newOrder={newOrder} agents={agents} accounts={accounts} searchTerm={searchTerm} tab={tab} setTab={setTab} setSearchFilterListAgents={setSearchFilterListAgents} setShowDetail={setShowDetail} showDetail={showDetail} setInitialAgent={setInitialAgent} initialAgent={initialAgent} searchFilterListAgents={searchFilterListAgents} accountState={accountState} />
        </React.Fragment>
    );
};