import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import configureStore from './utils/configureStore';


// Stylesheets
// ==========================================
// Stylesheets: Vendors
import './assets/stylesheets/vendors/normalize.scss';
import './assets/stylesheets/vendors/icons.scss';

// Stylesheets: Global
import './assets/stylesheets/sbx-webview.scss';

// Scripts
// ==========================================
// Scripts: Vendors
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle';


// Views
// ==========================================
// Views: Common
import * as serviceWorker from './serviceWorker';
import history from './utils/history';

// Views: Global
import { App } from './app';
import { URLParams, urlQueries } from './constants/utils/urlParams';



const store = configureStore({}, history);

const searchParam = new URLSearchParams(window.location.search);
let token = searchParam.get("token");
let callback = searchParam.get("callback");
let state = searchParam.get("state");
let tokenStorage = sessionStorage.getItem('@cloudAgentsClientToken');

callback && sessionStorage.setItem('@cloudAgentsCallback', callback);
state && sessionStorage.setItem('@cloudAgentsState', state);


if (tokenStorage && token) {
    sessionStorage.setItem('@cloudAgentsClientToken', token);
} else if (!tokenStorage && token) {
    sessionStorage.setItem('@cloudAgentsClientToken', token);
}
URLParams.register(urlQueries);




ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App} />
                {/* <Route render={() => {
          return <Redirect exact to="/" />
          }} /> */}
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
