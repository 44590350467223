import React from 'react';
import { Link } from 'react-router-dom';

// Localization
import _ from '../../../constants/i18n/engine';
import T9n from '../../../constants/i18n/translations';


export const AgentDetailsEmpty = () => {

    return (
        // <section className="view h-inherit">
        <div className="flex-column bg-body-tertiary p-3 mt-4 shadow h-inherit">
            <div className="container container-base text-center m-auto">
                <h2 className="sr-only visually-hidden">Agent - Empty</h2>
                <div className="display-3 fw-bold ls-n1 lh-1 mb-3">{_(T9n.LooksLike)} <br />{_(T9n.itsEmpty)}</div>
                <p className="text-lg mb-4 text-body-88">{_(T9n.unfortunately)}</p>
                <Link to="/agents" className="btn btn-warning">{_(T9n.retour)} </Link>
            </div>
        </div>
        // </section>
    );
};

