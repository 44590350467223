
function Document(data) {
    let document = data || {};
    this.id = document.id;
    this.name = document.name;
    this.synchCreationDate = document.synchCreationDate;
    this.accountId = document.accountId;
    this.customerAccountId = document.customerAccountId;
    this.customerUserId = document.customerUserId;
    this.agentId = document.agentId;
    this.agentIdentifier = document.agentIdentifier;
    this.contentHash = document.contentHash;
    this.hashAlgorithm = document.hashAlgorithm;
    this.contentType = document.contentType;
    this.metadatas = document.metadatas;
    this.uniqueIdentifier = document.uniqueIdentifier;
    this.uniqueIdentifierHash = document.uniqueIdentifierHash;
    this.documentProcessPhase = document.documentProcessPhase;
    this.size = (document.size / 1024).toFixed(1);
    this.deliveryDate = document.deliveryDate;
    this.acknowledgementDate = document.acknowledgementDate;
    this.base64Content = document.base64Content;
}

module.exports = Document;  