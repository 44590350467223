import React, { useState, useEffect } from 'react';
// import { Dropdown } from "bootstrap/dist/js/bootstrap.bundle";
import * as R from 'ramda';

import _ from '../../../constants/i18n/engine';
import T9n from '../../../constants/i18n/translations';
import { TAB, SORT_BY_ORDER } from '../../../constants/utils';


export const AgentListTabs = ({ switchSort, agents, searchFilterListAgents, newOrder, tab, setTab }) => {
    
    // useEffect(() => {
    //     const dropdownElementList = document.querySelectorAll('[data-bs-toggle="dropdown"]')
    //     const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl))
    //     return dropdownList;
    // }, []);

    return (
        <nav className="nav pt-2 cover">
            <h1 className="sr-only visually-hidden">SBX Webview - Agents: navigation</h1>
            <div className="container container-list">
                <div className="btn-group">
                    <button type="button" className="btn btn-if p-0 border-0" data-bs-toggle="dropdown"
                    aria-expanded="false">
                        {tab === TAB.MY_ACCOUNTS ? (
                            <span className="btn-text align-items-center">
                                <i className="icns bi bi-star-fill fs-5 lh-1 text-primary align-middle"></i>
                                <span className="align-middle mx-2">{_(T9n.my_accounts)}</span>
                            </span>
                        ) : (
                            <span className="btn-text align-items-center">
                                <i className="icns bi bi-list-ul fs-5 lh-1 text-primary align-middle"></i>
                                <span className="align-middle mx-2">{_(T9n.all_agents)}</span>
                            </span>
                        )}
                        <i className="icns bi bi-caret-down-fill lh-1 ms-2 align-middle fs-xs text-cover-66"></i>
                    </button>
                    <ul className="dropdown-menu border-none shadow">
                        <li className={"dropdown-item" + (tab === TAB.MY_ACCOUNTS ? " text-bg-body-tertiary" : "")} role="button" onClick={() => { setTab(TAB.MY_ACCOUNTS);}} >
                            <i className="icns bi bi-star fs-inherit me-xs text-primary"></i>
                            <span className="fs-sm ms-2">
                                {_(T9n.my_accounts)}
                            </span>
                        </li>
                        <li className={"dropdown-item" + (tab === TAB.ALL_AGENTS ? " text-bg-body-tertiary" : "")} role="button" onClick={() => { setTab(TAB.ALL_AGENTS); }} >
                            <i className="icns bi bi-list-ul lh-1 me-xs text-primary align-middle"></i>
                            <span className="fs-sm ms-2">
                                {_(T9n.all_agents)}
                            </span>
                        </li>
                        {/* <li className="dropdown-divider"></li>
                        <li className="dropdown-item">
                            <i className="icns bi bi-cursor-fill lh-1 align-middle text-primary"></i>
                            <span className="fs-sm ms-2">
                                {_(T9n.request)}
                                <span className="d-none d-lg-inline-block">
                                    {_(T9n.an_agent)}
                                </span>
                            </span>
                        </li> */}
                    </ul>
                </div>
                <div className="row align-items-center mb-1">
                    <div className="col">
                        <p className="fs-sm my-auto text-cover-88">
                            {!R.isNil(agents)
                                ? searchFilterListAgents.length > 1
                                    ? searchFilterListAgents.length +
                                    " " +
                                    _(T9n.agents) +
                                    " " +
                                    _(T9n.selected)
                                    : searchFilterListAgents.length +
                                    " " +
                                    _(T9n.agent) +
                                    " " +
                                    _(T9n.selected_one)
                                : 0 +
                                " " +
                                _(T9n.agent) +
                                " " +
                                _(T9n.selected_one)
                            }
                        </p>
                    </div>
                    <div className="col text-end">
                        <div className="flex-row align-items-center justify-content-end">
                            <span className="o-75 align-middle fs-sm me-2">{_(T9n.sortBy)}:</span>
                            <button type="button" className="btn p-0 bg-none fs-sm text-cover text-cover-88-hover" onClick={() => switchSort()}>
                                <span className="icns fs-5 me-1 text-primary">
                                    {newOrder === SORT_BY_ORDER.ASCENDING ? <i className="bi bi-sort-down"></i> : <i className="bi bi-sort-up"></i>}
                                </span>
                                <span className="fw-medium ms-1">
                                    {_(T9n.name)}
                                </span>
                            </button>
                        </div>


                        {/* <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle p-0 bg-none fs-sm text-cover text-cover-88-hover border-none" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setToggleDropdownOrder(!toggleDropdownOrder)}>
                                <span className="btn-text align-items-center btn  "> Order by:</span>
                            </button>
                            <ul className={`dropdown-menu  border-none shadow ${toggleDropdownOrder ? "show" : ""}`} aria-labelledby="dropdownMenuButton1">
                                <li><button type="button" className="dropdown-item" onClick={() => { setToggleDropdownOrder(false); setNewOrder(SORT_BY_ORDER.LAST_SYNC); }}>Action</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => setToggleDropdownOrder(false)}>Action</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => setToggleDropdownOrder(false)}>Action</button></li>
                            </ul>
                        </div> */}


                        {/* <li className="d-inline-block dropdown d-none">
                            <button type="button" className="btn dropdown-toggle lh-1 bg-none fs-sm" id="dropdown--sort-order" data-bs-toggle="dropdown" aria-expanded="false">
                                {_(T9n.sortBy)}
                                <span className="text-primary fw-medium ms-1">
                                    {_(T9n.name)}
                                </span>
                            </button>
                            <ul className="dropdown-menu border-none shadow" aria-labelledby="dropdown--sort-order" >
                                <button className="dropdown-item active" onClick={() => this.props.setSortByName() } >
                                    <i className="icns bi bi-sort-down me-2 text-primary fs-5"></i>
                                    <span className="fs-sm">
                                        {_(T9n.name)}
                                    </span>
                                </button>
                            </ul>
                        </li> */}
                    </div>
                </div>
            </div>
        </nav>
    );
};


