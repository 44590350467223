import * as ActionTypes from '../constants/actiontypes/agent';
import ClientJWT from '../constants/webapi/auth';




export function getAllAgents() {

    return (dispatch) => {
        dispatch({ type: ActionTypes.FETCH_AGENT_REQUEST });

        try {

            let client = new ClientJWT().getClient();

            return client.getAgents((error, response) => {
                if (error) {
                    dispatch({
                        type: ActionTypes.FETCH_AGENT_FAILURE,
                        error: 'Error on getting all the Agents!'
                    });
                    return error;

                }

                dispatch({
                    type: ActionTypes.FETCH_AGENT_SUCCESS,
                    data: response
                });
                return response;

            });

        } catch (error) {

            dispatch({
                type: ActionTypes.FETCH_AGENT_FAILURE,
                error: error.message
            });

        }

    };
}

