import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createRootReducer from '../reducers';
import { routerMiddleware } from 'connected-react-router';


export default function configureStore(initialState, browserHistory) {
    const store = createStore(
        createRootReducer(browserHistory),
        initialState,
        applyMiddleware(thunk, logger, routerMiddleware(browserHistory))
    );

    return store;
}