

function Customization(data) {
    let customization = data || {};
    this.logoBase64Encoded = customization.logoBase64Encoded;
    this.primaryColor = customization.primaryColor;
    this.accentColor = customization.accentColor;
    this.baseFont = customization.baseFont;
    this.auxiliaryFont = customization.auxiliaryFont;
    this.gdprComplianceText = customization.gdprComplianceText;
}
module.exports = Customization;