
export const urlQueries = ['searchText', 'page', 'sortBy', 'sort',];
export class URLParams {

    static register(entries) {
        if (!Array.isArray(entries)) {
            throw new Error(`Registered 'queries ${entries}' is not an array of strings. Eg.: ["sortBy", "sortDesc", "status" ]`);
        }
        this._entries = entries;
    }
    static querySearchParams() {
        return window.location.search;
    }

    static queries() {
        this.#getQueries();
        const queries = {};
        this._entries.forEach(entry => { queries[entry] = this[entry]; });
        return queries;
    }

    static #getQueries() {
        this.searchParams = new URLSearchParams(window.location.search);
        this._entries.forEach(entry => { this[entry] = this.searchParams.get(entry); });
    }

    static set(entries) {
        const url = new URL(window.location.href);
        Object.entries(entries).forEach(([key, value]) => url.searchParams.set(key, value));
        return url.search.toString();
    }

    static delete(entries) {
        const url = new URL(window.location.href);
        entries.forEach(entry => url.searchParams.delete(entry));
        return url.search.toString();
    }

    static reset({ remove, inital }) {
        const url = new URL(window.location.href);
        if (remove) this.#exclude(url, remove);
        if (inital) this.#setInitial(url, inital);
        return url.toString();
    }

    static #exclude(url, entriesToDelete) {
        entriesToDelete.forEach(entry => url.searchParams.delete(entry));
    }

    static #setInitial(url, entries) {
        Object.entries(entries).forEach(([key, value]) => url.searchParams.set(key, value));
    }

    static parseHash(hash) {
        if (typeof hash !== 'string') return;
        return hash
            .split("#")
            .filter(h => typeof h === 'string' && h.includes("="))
            .reduce((acc, curr) => {
                const [key, value] = curr.split("=");
                acc[key] = value;
                return acc;
            }, {});
    }
}

