import React, { useState, useEffect, useRef } from 'react';
import * as R from 'ramda';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import _ from '../../../../../../constants/i18n/engine';
import * as Loadings from '../../../../../../constants/loadings/auth';
import T9n from '../../../../../../constants/i18n/translations';
import { addAccount, updateAccount } from '../../../../../../actions/auth-api';



export const AgentDetailsDefaultAccountViewForm = ({ accountState, selectedAccount, setSelectedAccount, hasAccounts, agent, agentAccounts, setAgentAccounts, setMfaCode, setShowDetail, customizationState }) => {

    const { userId, accountId } = useParams();
    const dispatch = useDispatch();
    const [accountForm, setAccountForm] = useState({ ...selectedAccount });
    const [showPassword, setShowPassword] = useState(false);
    const fieldTypes = ["text", "password", "email", "tel"];
    let hasChanged = accountForm.name !== selectedAccount.name || accountForm.credentials !== selectedAccount.credentials;
    let isInvalid = false;
    const EMAIL_POSITION = 0;
    const PASSWORD_POSITION = 1;
    const DEFAULT_PASSWORD = '******';
    const resetAccounts = agentAccounts.filter(a => a.customerAccountId !== "");
    const formAcountDetails = useRef();

    let history = useHistory();

    useEffect(() => {
        if (selectedAccount.customerAccountId === "") return;
        setAccountForm({ ...selectedAccount });

    }, [selectedAccount]);


    const handleOnChangeNewAccount = (e, field, index) => {

        let newAccountForm = { ...accountForm };
        newAccountForm.credentials = newAccountForm.credentials.map((credential, i) => {

            if (credential.position === PASSWORD_POSITION &&
                !credential.value.includes(DEFAULT_PASSWORD) &&
                index === EMAIL_POSITION) {
                return credential;
            }

            if (e.target.value === "") {
                if (field.isRequired) {
                    isInvalid = true;
                }
            } else {

                let fieldRegex = new RegExp(field.regex);
                isInvalid = !fieldRegex.test(e.target.value);
            }


            if (credential.position === PASSWORD_POSITION &&
                fieldTypes[field.fieldInputType] !== 'password' &&
                selectedAccount.customerAccountId !== "") {

                return { ...credential, value: '', isInvalid: true };
            }


            if (i === index) {

                return { ...credential, value: e.target.value, isInvalid };

            } else if (field.fieldInputType === 0 && accountForm.customerAccountId !== "") {

                return credential;
            }

            return credential;
        });

        setAccountForm(newAccountForm);
    };

    const handleOnClick = (e, field, index) => {
        if (accountId === 'new-account') return;
        const currentSecondCredential = accountForm.credentials.find(c => c.position === PASSWORD_POSITION);
        if (!currentSecondCredential.value.includes(DEFAULT_PASSWORD)) return;

        if (field.position !== PASSWORD_POSITION && selectedAccount.customerAccountId === "") return;

        let newAccountForm = { ...accountForm };
        newAccountForm.credentials = newAccountForm.credentials.map((credential, i) => {

            if (credential.position === PASSWORD_POSITION && selectedAccount.customerAccountId !== "") {

                return { ...credential, value: '', isInvalid: false };
            }

            return credential;
        });

        setAccountForm(newAccountForm);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setMfaCode("");


        // const accounts = agentAccounts.filter(a => a.customerAccountId !== "")
        const accountId = resetAccounts.length > 0 ? resetAccounts[resetAccounts.length - 1].customerAccountId : "new-account";
        formAcountDetails.current.classList.add("was-validated");
        const inputName = document.querySelector("#account-name-");

        // const isNameInvalid = resetAccounts.map(a => a.name.toLowerCase()).includes(accountForm.name.toLowerCase());

        // if (isNameInvalid) {

        //     formAcountDetails.current.classList.remove("was-validated");
        //     inputName.classList.add("is-invalid");
        //     return;

        // } else {
        //     inputName.classList.remove("is-invalid");
        // }

        if (formAcountDetails.current.checkValidity()) {

            if (accountForm.customerAccountId !== "") {

                const originalFirstCredential = selectedAccount.credentials.find(c => c.position === EMAIL_POSITION);
                const currentFirstCredential = accountForm.credentials.find(c => c.position === EMAIL_POSITION);
                const currentSecondCredential = accountForm.credentials.find(c => c.position === PASSWORD_POSITION);

                if ((currentFirstCredential.value === originalFirstCredential.value) &&
                    currentSecondCredential.value.includes(DEFAULT_PASSWORD)) {
                    const { credentials, ...rest } = accountForm;
                    dispatch(updateAccount(accountForm.customerAccountId, rest));
                } else {
                    dispatch(updateAccount(accountForm.customerAccountId, accountForm));
                }

                history.push(`/u/${userId}/agents/${agent.id}/${selectedAccount.customerAccountId}`);
                formAcountDetails.current.classList.remove("was-validated");
                return;

            } else {
                dispatch(addAccount(accountForm))
                    .then((account) => {

                        history.push(`/u/${userId}/agents/${agent.id}/${account.customerAccountId}`);

                    }).catch(e => {

                        history.push(`/u/${userId}/agents/${agent.id}/${accountId}`);
                    });
            }

            formAcountDetails.current.classList.remove("was-validated");
            history.push(`/u/${userId}/agents/${agent.id}/new-account`);
        }


    };

    const redirectToAgentsList = () => {

        setShowDetail(false);
        setTimeout(() => history.push(`/u/${userId}/agents`), 450);
    };





    return (
        !R.isNil(agent) && !R.isNil(selectedAccount) ?
            <React.Fragment>
                <section className="block mb-3" id="supplier--account-credentials">
                    <div className="container-fluid">
                        <h2 className="fs-5 my-2">
                            {/* <i className="icns bi bi-sliders me-3"></i> */}
                            <span className="o-100">{_(T9n.overview)}</span>
                        </h2>
                        <div className="row">
                            <div className="col-xl">
                                <div className="block text-body-88 mt-0 small lh-base pe-hd-5" >{_(T9n.account_description)}</div>
                            </div>
                            <form className="col-xl-8" ref={formAcountDetails} noValidate onSubmit={handleSubmit} >
                                {agent.agentCurrentState !== 0 ?
                                    <div className="block pe-none">
                                        {agent.agentCurrentState !== 0 ?
                                            hasAccounts ?

                                                <p className="mt-0 small lh-base ff-aux text-danger fw-medium bg-danger-subtle p-2 rounded text-center" >{_(T9n.account_disabled)}</p>
                                                :
                                                <p className="mt-0 small lh-base ff-aux text-danger fw-medium bg-danger-subtle p-2 rounded text-center" >{_(T9n.accountNone_disabled)}</p>
                                            : ''
                                        }

                                        <div className="disabled text-body-88">
                                            <div className="block mb-3">
                                                <label className="label fw-demi text-uppercase small ls-2 text-body-66" htmlFor="account-name" >{_(T9n.name)}</label>
                                                <input type="text" className="form-control line-form" id="account-name-" placeholder="Nom" value={accountForm.name} onChange={(e) => setAccountForm({ ...selectedAccount, name: e.target.value })} />
                                            </div>

                                            {agent.fields.map((field, index) => {
                                                let className = "db";
                                                // if(agent.fields[index].isRequired && accountForm === ""){
                                                //     className += " is-invalid";
                                                // }

                                                return (

                                                    <div key={"agent-field-" + field.position} className={className}>
                                                        <label className="label fw-demi text-uppercase small ls-2 mt-2 text-body-66 " htmlFor={"agent-field-" + field.position}>{field.name}</label>
                                                        <div className="input-group mb-3">
                                                            <input className="form-control line-form" id={"agent-field-" + field.position} type={fieldTypes[field.fieldInputType]} placeholder={field.placeHolder} defaultValue={accountForm.credentials[index].value}

                                                                onChange={(e) => {

                                                                    setAccountForm({
                                                                        ...accountForm, credentials: accountForm.credentials.map((credential, i) => {

                                                                            if (i === index) {

                                                                                return { ...credential, value: e.target.value };
                                                                            }
                                                                            else {
                                                                                return credential;
                                                                            }
                                                                        })
                                                                    });
                                                                }}
                                                                aria-describedby="hint-end"

                                                            />
                                                            <div className="input-group-text bg-none border-none input-hint" id={"hint-end" + index} data-original-title={field.hint} aria-label="Hint" data-toggle="tip">
                                                                ?
                                                                <div tip-data="info" tip-placement="center bottom right" className="rounded-1 bg-body text-center p-2 fw-base fs-aux w-auto">{field.hint}</div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                );
                                            })}

                                        </div>
                                    </div>
                                    :

                                    <div className="block">
                                        <div className="block mb-3">
                                            <label className="label fw-demi text-uppercase small ls-2 text-body-66" htmlFor="account-name" >{_(T9n.name)}</label>
                                            <input type="text" className="form-control line-form" id="account-name-" placeholder="Nom" value={accountForm.name}
                                                onChange={(e) => setAccountForm({
                                                    ...selectedAccount, name: e.target.value, credentials: accountForm.credentials.map((credential, i) => {
                                                        if (i === 1 && selectedAccount.customerAccountId !== "") {
                                                            //return { ...credential, value: '', isInvalid: true };
                                                        }
                                                        return credential;
                                                    })
                                                })} />
                                            <div id="account-name-" className="invalid-feedback">{_(T9n.sameAccountName)}</div>
                                        </div>

                                        {agent.fields.map((field, index) => {
                                            let className = "db";
                                            return (
                                                <div key={"agent-field-" + field.position} className={className} >
                                                    <label className="label fw-demi text-uppercase small ls-2 mt-2 text-body-66" htmlFor={"agent-field-" + field.position}>{field.name}</label>
                                                    <div className="input-group flex-row gap-2 mb-3">
                                                        <input required={field.isRequired} pattern={field.regex} className="form-control line-form" id={"agent-field-" + field.position} type={field.position === 1 && showPassword ? "text" : fieldTypes[field.fieldInputType]} placeholder={field.placeHolder} onChange={(e) => handleOnChangeNewAccount(e, field, index)} onFocus={(e) => { handleOnClick(e, field, index); }} value={accountForm.credentials[index].value} aria-describedby="hint-end" />
                                                        <div className="input-group-text bg-none border-none input-hint" id={field.hint} data-original-title={field.hint} aria-label="Hint" data-toggle="tip">
                                                            ?
                                                            <div tip-data="info" tip-placement="center bottom right" className="rounded-1 bg-body text-center p-2 fw-base fs-aux w-auto">{field.hint}</div>
                                                        </div>
                                                        {field.position === 1 && <div onClick={() => { setShowPassword(p => !p); }} className='me-5 input-group-text bg-none border-none input-hint'><i className="icns  bi bi-eye"></i></div>}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {hasChanged || accountForm.customerAccountId === "" ?

                                            <footer className="block mt-3 text-end">
                                                {!agentAccounts.length > 0 ?

                                                    <Link to="/" type="button" disabled={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT)} className="btn btn-error me-2" >{_(T9n.cancel)}</Link>
                                                    :
                                                    selectedAccount.customerAccountId === "" && agentAccounts.length > 0 ?
                                                        <button className="btn btn-error me-2" disabled={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT)}
                                                            onClick={() => {
                                                                setAgentAccounts(R.isEmpty(resetAccounts) ? [...agentAccounts] : resetAccounts);
                                                                setSelectedAccount(R.isEmpty(resetAccounts) ? agentAccounts[0] : resetAccounts[resetAccounts.length - 1]);
                                                                resetAccounts.length > 0 ? history.push(`/u/${userId}/agents/${agent.id}/${resetAccounts[resetAccounts.length - 1].customerAccountId}`) : redirectToAgentsList();
                                                            }
                                                            } >{_(T9n.cancel)}</button>
                                                        :
                                                        <button type="button" className={"btn btn-error me-2 " + ((accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT)) ? "disabled" : "")} disabled={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT)} onClick={() => { setAccountForm(selectedAccount); }} >{_(T9n.cancel)}</button>

                                                }
                                                <button type="submit" className={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT) ? "btn btn-add disabled" : "btn btn-add"} id="triggerMFA" disabled={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT)} >{_(T9n.save)}</button>
                                            </footer>
                                            :
                                            <footer className="block mt-3 text-end">
                                                <button type="button" className="btn btn-add disabled">
                                                    {_(T9n.save)}
                                                </button>
                                            </footer>
                                        }

                                    </div>
                                }

                            </form>

                        </div>
                    </div>
                </section>
                {/* <div className="block py-4">
                    <div className="container-fluid">
                        <div className="fs-xs text-body-88 my-xl">
                            {!R.isNil(customizationState?.customization) && !R.isNil(customizationState.customization.gdprComplianceText) ?
                                customizationState.customization.gdprComplianceText
                                :
                                _(T9n.data_disclosure)
                            }
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
            :
            ""

    );

}

