
const T9n = {
    accounts: {
        'en-GB': 'accounts',
        'fr-FR': 'comptes',
        'pt-PT': 'contas',
    },
    account: {
        'en-GB': 'account',
        'fr-FR': 'compte',
        'pt-PT': 'conta',
    },
    overview: {
        'en-GB': 'Credentials',
        'fr-FR': 'Identifiants',
        'pt-PT': 'Credenciais',
    },
    credentials: {
        'en-GB': 'Credentials',
        'fr-FR': 'Identifiants',
        'pt-PT': 'Credenciáis',
    },
    delete: {
        'en-GB': 'Delete',
        'fr-FR': 'Supprimer',
        'pt-PT': 'Apagar',
    },
    edit: {
        'en-GB': 'Edit',
        'fr-FR': 'Éditer',
        'pt-PT': 'Modificar',
    },
    save: {
        'en-GB': 'Save',
        'fr-FR': 'Enregistrer',
        'pt-PT': 'Guardar',
    },
    inMaintenance: {
        'en-GB': 'In maintenance',
        'fr-FR': 'En maintenance',
        'pt-PT': 'Em manutenção',
    },
    unavailable: {
        'en-GB': 'Unavailable',
        'fr-FR': 'Indisponible',
        'pt-PT': 'Indisponível',
    },
    sync: {
        'en-GB': 'Sync',
        'fr-FR': 'Sync',
        'pt-PT': 'Sincronizar',
    },
    status: {
        'en-GB': 'Status',
        'fr-FR': 'Statut',
        'pt-PT': 'Estado',
    },
    synchronization: {
        'en-GB': 'Synchronisation',
        'fr-FR': 'Synchronisation',
        'pt-PT': 'Sincronização',
    },
    last_sync: {
        'en-GB': 'Last sync: ',
        'fr-FR': 'Dernière synchro: ',
        'pt-PT': 'Última sincronização: ',
    },
    mfa_code: {
        'en-GB': 'Code de Synchronisation',
        'fr-FR': 'Envoi de code',
        'pt-PT': 'Código de Syncronização',
    },
    mfa_description: {
        'en-GB': 'Please enter the sync code of the provider account.',
        'fr-FR': 'Entrez votre code de synchronisation du compte fournisseur.',
        'pt-PT': 'Inserir o código de syncronização da conta.',
    },
    mfa_label: {
        'en-GB': 'Provider Code',
        'fr-FR': 'Code Fournisseur',
        'pt-PT': 'Codigo',
    },
    mfa_placeholder: {
        'en-GB': 'Type code here...',
        'fr-FR': 'Code Fournisseur...',
        'pt-PT': 'Digite codigo aqui...',
    },
    check_details: {
        'en-GB': 'Check Details',
        'fr-FR': 'Voir Détails',
        'pt-PT': 'Ver Detalhes',
    },
    add: {
        'en-GB': 'Add',
        'fr-FR': 'Ajouter',
        'pt-PT': 'Adicionar',
    },
    loading: {
        'en-GB': 'Loading...',
        'fr-FR': 'En cours...',
        'pt-PT': 'Carregamento...',
    },
    request: {
        'en-GB': 'Request ',
        'fr-FR': 'Proposer ',
        'pt-PT': 'Pedir ',
    },
    an_agent: {
        'en-GB': 'an agent',
        'fr-FR': 'un agent',
        'pt-PT': 'um agente',
    },
    search: {
        'en-GB': 'Search ...',
        'fr-FR': 'Recherchez ...',
        'pt-PT': 'Procurar ...',
    },
    my_accounts: {
        'en-GB': 'My Accounts',
        'fr-FR': 'Mes Comptes',
        'pt-PT': 'As Minhas Contas',
    },
    show: {
        'en-GB': 'Show',
        'fr-FR': 'Montrer',
        'pt-PT': 'Mostrar',
    },
    showAgentsList: {
        'en-GB': 'Show agents list',
        'fr-FR': 'Voir la liste des agents',
        'pt-PT': 'Mostrar lista dos agentes',
    },
    all_agents: {
        'en-GB': 'Agents',
        'fr-FR': 'Agents',
        'pt-PT': 'Agentes',
    },
    agents: {
        'en-GB': 'agents',
        'fr-FR': 'agents',
        'pt-PT': 'agentes',
    },
    agent: {
        'en-GB': 'agent',
        'fr-FR': 'agent',
        'pt-PT': 'agente',
    },
    selected: {
        'en-GB': 'selected',
        'fr-FR': 'sélectionnés',
        'pt-PT': 'seleccionados',
    },
    selected_one: {
        'en-GB': 'selected',
        'fr-FR': 'sélectionné',
        'pt-PT': 'seleccionado',
    },
    sortBy: {
        'en-GB': 'Sort by',
        'fr-FR': 'Trier par',
        'pt-PT': 'Ordenar por',
    },
    name: {
        'en-GB': 'Name',
        'fr-FR': 'Nom',
        'pt-PT': 'Nome',
    },
    add_account: {
        'en-GB': 'Add Account',
        'fr-FR': 'Ajouter Compte',
        'pt-PT': 'Adicionar Conta',
    },
    downloaded_docs: {
        "en-GB": "document(s) retrieved",
        "fr-FR": "document(s) récupéré(s)",
        "pt-PT": "documento(s) recuperado(s)"
    },
    account_description: {
        'en-GB': 'These data allow the service to collect your documents available on the issuing website. You may access and get a copy of the data, oppose to their processing, demand their correction/deletion as well as limit their processing. You may find more information regarding these data processing at the bottom of this page',
        'fr-FR': "Ces données permettent la collecte de vos documents disponibles sur le site émetteur. Vous pouvez accéder et obtenir une copie des données vous concernant, vous opposer au traitement de ces données, les faire rectifier/effacer ainsi que limiter leur traitement. Vous pouvez retrouver en bas de page plus d'informations concernant le traitement de ces données.",
        'pt-PT': 'Estes dados permitem a recolha dos seus documentos disponíveis no site emissor. Você pode aceder e obter cópia dos dados que lhe dizem respeito, opor-se ao tratamento destes dados, pedir a sua correcção/eliminação e ainda limitar o seu tratamento. Pode encontrar mais informações sobre o tratamento destes dados no fundo desta página.',
    },
    account_disabled: {
        'en-GB': 'Synchronization for this account is disabled. To find out more please read the status info.',
        'fr-FR': "La synchronisation de ce compte est désactivé. Pour en savoir plus, veuillez lire l'état.",
        'pt-PT': 'A sincronização para esta conta está desativada. Para saber mais, por favor, leia a informação do estado.',
    },
    accountNone_disabled: {
        'en-GB': 'Account creation is disabled. To find out more please read the status info.',
        'fr-FR': "La création de compte est désactivée. Pour en savoir plus, veuillez lire l'état.",
        'pt-PT': 'A criação da conta está desativada. Para saber mais, por favor, leia a informação do estado.',
    },
    data_disclosure: {
        'en-GB': "These data are requested in order to automatically collect your documents available on the transmitting site. The legal basis is the GDPR's Article 6.1.a about consent. The recipients of the data are the data controller, the internal services in charge of synchronization and maintenance as well as any legally authorized person. The data is located in a server hosted by Microsoft Azure Amsterdam. The duration of data processing is limited to the time that your account is created, on the understanding that you can withdraw your consent by deleting your account at any time. You have the right to ask the data controller for access to personal data, the correction or deletion of personal data, or a processing limitation regarding the data subject, or the right to oppose the processing and the right of data portability. The controller is SECURIBOX SARL, 23 rue Balzac 75008 PARIS SIREN 500003710 - email: privacy@securibox.eu. You also have the right to lodge a complaint before a data protection authority.",
        'fr-FR': "Ces données sont demandées afin de collecter de façon automatique vos documents disponibles sur le site émetteur. La base légale est l'article 6.1.a du RGPD (consentement). Les destinataires des données sont le responsable de traitement, les services internes en charge de la synchronisation et la maintenance ainsi que toute personne légalement autorisée. Le serveur sur lequel sont hébergés vos données est hébergé par Microsoft Azure Amsterdam. La durée de traitement des données est limitée au temps que votre compte est créé, étant entendu que vous pouvez retirer votre consentement en supprimant votre compte à tout moment. Vous disposez du droit de demander au responsable du traitement l'accès aux données à caractère personnel, la rectification ou l'effacement de celles-ci, ou une limitation du traitement relatif à la personne concernée, ou du droit de s'opposer au traitement et du droit à la portabilité des données. Le responsable du traitement est la société  SECURIBOX SARL, 23 rue Balzac 75008 PARIS SIREN 500003710  - email: privacy@securibox.eu. Vous avez également le droit d'introduire une réclamation auprès d'une autorité de contrôle.",
        'pt-PT': "Estas dados são pedidos para recuperar de maneira automatica os seus documentos disponíveis no site emissor. A base legal é o artigo 6.1.a do RGPD (consentimento). Os destinatários dos dados são o responsável do tratamento, os serviços internos encarregados das sincronizações et manutenção tal como qualquer pessoa legalmente autorizada. O servidor no qual estão hospedados os dados é hospedado pela Microsoft Azure em Amsterdão. A duração do tratamento destes dados é limitado ao tempo em que a conta está criada, estando claro que pode retirar o consentimento eliminando a sua conta a qualquer momento. Dispõe do direito de pedir ao responsável do tratamento acesso aos seus dados pessoais, rectificação e eliminação destes, ou limitação do tratamento relativo à pessoa que diz respeito, ou do direito de oposição ao tratamento e do direito de portabilidade dos seus dados. O responsável do tratamento é a empresa SECURIBOX SARL, 23 rue Balzac 75008 PARIS SIREN 500003710  - email: privacy@securibox.eu. Tem igualemente o direito de apresentar uma reclamação junto de entidade de controle.",
    },
    delete_button: {
        'en-GB': 'I want to delete my account',
        'fr-FR': 'Je veux supprimer mon compte',
        'pt-PT': 'Quero apagar a minha conta',
    },
    help: {
        'en-GB': 'Help',
        'fr-FR': 'Aide',
        'pt-PT': 'Ajuda',
    },
    delete_account: {
        'en-GB': 'Delete the account',
        'fr-FR': 'Supprimer le compte',
        'pt-PT': 'Apagar a conta',
    },
    delete_question: {
        'en-GB': 'Are you sure that you want to delete the account ',
        'fr-FR': 'Êtes-vous sûr de vouloir supprimer le compte ',
        'pt-PT': 'Quer mesmo apagar a conta ',
    },
    delete_information: {
        'en-GB': 'Deleting the account will erase every related information and data, namely documents, access credentials and synch history.',
        'fr-FR': 'La suppression du compte supprimera toutes les informations et données associées, notamment documents, identifiants de connexion et historique de synchronisations.',
        'pt-PT': 'Eliminando a conta vai apagar todas as informações e dados associados, nomeadamente os documentos, as credenciáis de acesso e o histórico das sincronizações.',
    },
    delete_confirmation: {
        'en-GB': 'Yes, delete the account',
        'fr-FR': 'Oui, supprimer le compte',
        'pt-PT': 'Sim, apagar a conta',
    },
    default_account_name: {
        'en-GB': 'New Account',
        'fr-FR': "Nouveau compte",
        'pt-PT': "Nova Conta",
    },
    cancel: {
        'en-GB': 'Cancel',
        'fr-FR': 'Annuler',
        'pt-PT': 'Anular',
    },
    seconds: {
        'en-GB': 'seconds',
        'fr-FR': 'secondes',
        'pt-PT': 'segundos',
    },
    minutes: {
        'en-GB': 'minutes',
        'fr-FR': 'minutes',
        'pt-PT': 'minutos',
    },
    hours: {
        'en-GB': 'hours',
        'fr-FR': 'heures',
        'pt-PT': 'horas',
    },
    days: {
        'en-GB': 'days',
        'fr-FR': 'jours',
        'pt-PT': 'dias',
    },
    mfa_enter_code: {
        'en-GB': 'ENTER MFA CODE',
        'fr-FR': 'ENTER MFA CODE',
        'pt-PT': 'ENTER MFA CODE',
    },
    sync_description: {
        'en-GB': 'Start a new account synchronization by clicking on the "Sync" button. Synchronizations may take a few minutes to complete.',
        'fr-FR': 'Lancez une synchronisation du compte fournisseur en cliquant sur le bouton "Sync". Une synchronisation peut prendre plusieurs minutes.',
        'pt-PT': 'Para lançar uma sincronização da conta carregue no butão "Sincronizar". A sincronização pode demorar alguns minutos.',
    },
    status_description: {
        'en-GB': 'Please read below to find more.',
        'fr-FR': "Pour en savoir plus, veuillez lire ci-dessous.",
        'pt-PT': 'Para saber mais, por favor, leia em baixo.',
    },
    // SynchState and SynchStateDetails 
    AccountBlocked: {
        "en-GB": "Account Blocked && Please unblock your account &&on the supplier's website%",
        "fr-FR": "Compte Bloqué &&Veuillez débloquer le compte &&sur le site du fournisseur%",
        "pt-PT": "Conta Bloqueada &&É necessário desbloquear a conta &&no site do fornecedor%"
    },
    AccountDisabled: {  // No corresponging item in enum (?)
        "en-GB": "Your account is disabled, please contact your administrator.",
        "fr-FR": "Votre compte est désactivé, veuillez contacter votre administrateur",
        "pt-PT": "A sua conta está desativada, contate o administrador"
    },
    AdditionalAuthenticationRequired: {
        "en-GB": "The synchronization is not possible.&& Please deactivate the double authenficiation service &&on the supplier's website%.",
        "fr-FR": "La collecte n'est pas possible.&& Veuillez si possible, désactiver la double authentification sur le site fournisseur. &&sur le site du fournisseur%.",
        "pt-PT": "A sincronização é impossível.&& Por favor desative a dupla autenticação &&no site do fornecedor%."
    },
    AgentFailed: {
        "en-GB": "Failed",
        "fr-FR": "Echouée",
        "pt-PT": "Falhada"
    },
    CaptchaFound: {
        "en-GB": "The synchronization has failed with a temporary error and will be tried again later.",
        "fr-FR": "La collecte a échouée suite à un problème temporaire et sera réessayée ultérieurement.",
        "pt-PT": "A sincronização falhou depois de um error temporário e será tentada de novo mais tarde."
    },
    CheckAccount: {
        "en-GB": "Wrong credentials && Verify your credentials && on the supplier's website%.",
        "fr-FR": "Identifiants Incorrects && Vérifiez vos identifiants &&sur le site du fournisseur%.",
        "pt-PT": "Credenciais erradas && Verifique as suas credenciais &&no site no fornecedor%."
    },
    Completed: {
        "en-GB": "Success",
        "fr-FR": "Succès @@  document(s) récupéré(s)",
        "pt-PT": "Sucesso"
    },
    CompletedNothingNewToDownload: {
        "en-GB": "Success",
        "fr-FR": "Succès",
        "pt-PT": "Sucesso"
    },
    CompletedNothingToDownload: {
        "en-GB": "Success",
        "fr-FR": "Succès",
        "pt-PT": "Sucesso"
    },
    CompletedWithErrors: {
        "en-GB": "An error occurred.&& We are aware of this situation and this account will be automatically synchronized once a fix has been made available for this aggregation program. No further action is required on your part.",
        "fr-FR": "Une erreur est survenue.&& Nous sommes au courant de cette situation et la collecte de ce compte sera automatiquement relancée quand le correctif de ce programme de collecte sera rendu disponible. Aucune action supplémentaire n'est requise de votre part.",
        "pt-PT": "Ocorreu um erro.&& Tomamos conhecimento desta situação e esta conta será automaticamente sincronizada quando este programa de agregação for corrigido. Nenhuma ação sua é necessária."
    },
    CompletedWithMissingDocs: {
        "en-GB": "Missing Docs",
        "fr-FR": "Documents Manquants",
        "pt-PT": "Documentos em Falta"
    },
    CreateAccountToaster: {
        "en-GB": "You don't have the required permissions to create more accounts.",
        "fr-FR": "Vous n'avez pas les permissions nécessaires pour créer plus comptes",
        "pt-PT": "Você não tem as permissões necessárias para criar mais contas."
    },
    Created: {
        "en-GB": "Created",
        "fr-FR": "Créé",
        "pt-PT": "Criada"
    },
    Delivering: {
        "en-GB": "Delivering",
        "fr-FR": "Livraison",
        "pt-PT": "Entregando"
    },
    DematerialisationNeeded: {
        "en-GB": "Dematerialisation Needed",
        "fr-FR": "Dématérialisation Nécessaire",
        "pt-PT": "Desmaterialização Necessária"
    },
    Error: {
        "en-GB": "Error",
        "fr-FR": "Erreur",
        "pt-PT": "Erro"
    },
    Errors: {
        "en-GB": "Errors",
        "fr-FR": "Erreurs",
        "pt-PT": "Erros"
    },
    InProgress: {
        "en-GB": "The synchronization is in progress",
        "fr-FR": "La synchronisation est en cours",
        "pt-PT": "A sincronização está a decorrer."
    },
    LoginPageChanged: {
        "en-GB": "An error occurred. &&We are aware of this situation and this account will be automatically synchronized once a fix has been made available for this aggregation program. No further action is required on your part.",
        "fr-FR": "Une erreur est survenue. &&Nous sommes au courant de cette situation et la collecte de ce compte sera automatiquement relancée quand le correctif de ce programme de collecte sera rendu disponible. Aucune action supplémentaire n'est requise de votre part.",
        "pt-PT": "Ocorreu um erro.&&Tomamos conhecimento desta situação e esta conta será automaticamente sincronizada quando este programa de agregação for corrigido. Nenhuma ação sua é necessária."
    },
    NewAccount: {
        "en-GB": "Account created",
        "fr-FR": "Compte créé",
        "pt-PT": "Conta criada"
    },
    Pending: {
        "en-GB": "The synchronization will start in a few instants",
        "fr-FR": "La synchronisation se déroulera automatiquement dans quelques instants",
        "pt-PT": "A sincronização vai começar dentro de instantes"
    },
    PendingAcknowledgement: {
        "en-GB": "Pending Acknowledgement",
        "fr-FR": "Attente Accusé Réception",
        "pt-PT": "Aguardando Confirmação"
    },
    PersonalNotification: {
        "en-GB": "Your action is required ##on the supplier's website$ ##(terms validation, Pop-up validation, etc…)",
        "fr-FR": "Une action de votre part est nécessaire ##sur le site du fournisseur$ ##(Validation CGU, Validation Pop-up, etc…)",
        "pt-PT": "Um ação sua é necessária ##no site do fornecedor$ ## (validação das CGU, validação de um pop-up, etc…)"
    },
    ReportFailed: {
        "en-GB": "Report Failed",
        "fr-FR": "Envoi Echoué",
        "pt-PT": "Envio Falhou"
    },
    ResetPasswordRequired: {
        "en-GB": "The password must be reset &&on the supplier's website%",
        "fr-FR": "Le mot de passe doit être modifié &&sur le site du fournisseur%",
        "pt-PT": "A palavra-passe deev ser alterada &&no site do fornecedor%"
    },
    ResetPasswordWarning: {
        "en-GB": "Attention, the password must be reset soon &&on the supplier's website%",
        "fr-FR": "Attention, le mot de passe devra bientôt être modifié &&sur le site du fournisseur%",
        "pt-PT": "Alerta, a palavra-passe deve em breve ser alterada &&no site do fornecedor%"
    },
    Running: {
        "en-GB": "Running",
        "fr-FR": "En Fonctionnement",
        "pt-PT": "Em Execução"
    },
    Scheduled: {
        "en-GB": "The synchronization will start in a moment",
        "fr-FR": "La synchronisation se déroulera automatiquement dans quelques instants",
        "pt-PT": "A sincronização vai começar dentro de instantes"
    },
    ServerUnavailable: {
        "en-GB": "The supplier's website is unavailable.&& An automatic synchronization will be launched later",
        "fr-FR": "Le site du fournisseur est indisponible.&& Une synchronisation automatique sera lancée plus tard",
        "pt-PT": "O site do fornecedor está indisponível.&& Uma sincronização será lançada mais tarde"
    },
    TemporaryServerError: {
        "en-GB": "The supplier's website is unavailable.&& An automatic synchronization will be launched later",
        "fr-FR": "Le site du fournisseur est indisponible.&& Une synchronisation automatique sera lancée plus tard",
        "pt-PT": "O site do fornecedor está indisponível.&& Uma sincronização será lançada mais tarde"
    },
    WebsiteChanged: {
        "en-GB": "An error occurred.&& We are aware of this situation and this account will be automatically synchronized once a fix has been made available for this aggregation program. No further action is required on your part.",
        "fr-FR": "Une erreur est survenue.&& Nous sommes au courant de cette situation et la collecte de ce compte sera automatiquement relancée quand le correctif de ce programme de collecte sera rendu disponible. Aucune action supplémentaire n'est requise de votre part.",
        "pt-PT": "Ocorreu um erro.&& Tomamos conhecimento desta situação e esta conta será automaticamente sincronizada quando este programa de agregação for corrigido. Nenhuma ação sua é necessária."
    },
    WebsiteInMaintenance: {
        "en-GB": "The supplier's website is unavailable.&& An automatic synchronization will be launched later",
        "fr-FR": "Le site du fournisseur est indisponible.&& Une synchronisation automatique sera lancée plus tard",
        "pt-PT": "O site do fornecedor está indisponível.&& Uma sincronização será lançada mais tarde"
    },
    WelcomePageChanged: {
        "en-GB": "An error occurred.&& We are aware of this situation and this account will be automatically synchronized once a fix has been made available for this aggregation program. No further action is required on your part.",
        "fr-FR": "Une erreur est survenue.&& Nous sommes au courant de cette situation et la collecte de ce compte sera automatiquement relancée quand le correctif de ce programme de collecte sera rendu disponible. Aucune action supplémentaire n'est requise de votre part.",
        "pt-PT": "Ocorreu um erro.&& Tomamos conhecimento desta situação e esta conta será automaticamente sincronizada quando este programa de agregação for corrigido. Nenhuma ação sua é necessária."
    },
    WrongCredentials: {
        "en-GB": "Wrong credentials && Verify your credentials &&on the supplier's website%.",
        "fr-FR": "Identifiants Incorrects && Vérifiez vos identifiants &&sur le site du fournisseur%.",
        "pt-PT": "Credenciais erradas && Verifique as suas credenciais &&no site no fornecedor%."
    },
    WrongCredentialsSynchs: {
        "en-GB": "Wrong Credentials",
        "fr-FR": "Identifiants incorrects",
        "pt-PT": "Identificadores incorrectos"
    },
    ThirdPartyIssuesSynchs: {
        "en-GB": "Website Issue or User Action Required",
        "fr-FR": "Erreur du Site ou Action Utilisateur Requise",
        "pt-PT": "Error do Website ou Ação Utilizador Necessária"
    },
    WrongMFACode: {
        "en-GB": "The additional code is incorrect.&& Please try synchronizing again",
        "fr-FR": "Le code supplémentaire fourni est incorrect.&& Veuillez tenter à nouveau la synchronisation",
        "pt-PT": "O código adicional fornecido está incorreto.&& Tente de novo a sincronização"
    },
    MFAExplanation: {
        "en-GB": "When synchronizing this agent, you will be requested to submit a secret code sent to you through another channel (sms, e-mail, etc...).",
        "fr-FR": "Lors de la synchronisation de cet agent, vous serez solicité afin d'introduire le code d'authentification forte reçu via un autre canal (sms, e-mail, etc...).",
        "pt-PT": "Aquando da sincronização deste agente, será pedido que insira o código secreto enviado por outro canal (sms, e-mail, etc...)."
    },
    Submit: {
        "en-GB": "Submit",
        "fr-FR": "Valider",
        "pt-PT": "Validar"
    },
    agentUrl: {
        "en-GB": "supplier's website",
        "fr-FR": "sur le site du fournisseur",
        "pt-PT": "site do fornecedor"
    },
    errorLoadingAgents: {
        "en-GB": "Error on loading all the Agents.",
        "fr-FR": "Erreur lors du chargement de tous les Agents.",
        "pt-PT": "Erro ao carregar todos os Agentes."
    },
    errorRefreshPage: {
        "en-GB": "Please refresh the page or",
        "fr-FR": "Veuillez rafraîchir la page ou",
        "pt-PT": "Atualize a página ou"
    },
    contactUs: {
        "en-GB": "contact us",
        "fr-FR": "contactez-nous",
        "pt-PT": "contacte-nos"
    },
    LooksLike: {
        "en-GB": "Looks like",
        "fr-FR": "On dirait",
        "pt-PT": "Não"
    },
    itsEmpty: {
        "en-GB": "it's empty.",
        "fr-FR": "que c'est vide.",
        "pt-PT": "encontrado."
    },
    unfortunately: {
        "en-GB": "Unfortunately, we can't seem to find the content you're looking for.",
        "fr-FR": "Malheureusement, nous ne parvenons pas à trouver le contenu que vous recherchez.",
        "pt-PT": "Infelizmente, não conseguimos encontrar o conteúdo que você está procurando."
    },
    noAccountConfigured: {
        "en-GB": "There are no accounts configured.",
        "fr-FR": "Il n'y a pas de comptes configurés.",
        "pt-PT": "Não há contas configuradas."
    },
    spelledCorrectly: {
        "en-GB": "Make sure that the name are spelled correctly.",
        "fr-FR": "Veuillez vérifier que le nom est correctement écrit..",
        "pt-PT": "Verifique se o nome está escrito corretamente."
    },
    yourSearch: {
        "en-GB": "Your search",
        "fr-FR": "La recherche",
        "pt-PT": "Sua pesquisa"
    },
    didNotMatch: {
        "en-GB": "did not match any Agent",
        "fr-FR": "ne correspond à aucun agent.",
        "pt-PT": "não correspondeu a nenhum agente."
    },
    dontSee: {
        "en-GB": "Don't see what you're looking for?",
        "fr-FR": "Vous ne trouvez pas ce que vous recherchez?",
        "pt-PT": "Não consegue encontrar o que você está a procura?"
    },
    addAgent: {
        "en-GB": "Add Agent",
        "fr-FR": "Ajouter un agent",
        "pt-PT": "Criar agent?"
    },
    retour: {
        "en-GB": "Voltar",
        "fr-FR": "Retour",
        "pt-PT": "Go Back"
    },
    noFiles: {
        "en-GB": "No Files",
        "fr-FR": "Aucun fichier",
        "pt-PT": "Sem arquivos"
    },
    noFilesYet: {
        "en-GB": "You do not have any files yet!",
        "fr-FR": "Vous n'avez pas encore de fichiers!",
        "pt-PT": "Atualmente não há nenhum arquivo!"
    },
    LoadingError: {
        "en-GB": "Loading Error",
        "fr-FR": "Erreur de chargement",
        "pt-PT": "Erro ao Carregar Ficheiros"

    },
    FilesLoadingError: {
        "en-GB": "Error on loading your files, try later!",
        "fr-FR": "Erreur lors du chargement de vos fichiers, essayez plus tard!",
        "pt-PT": "Erro ao carregar seus arquivos, tente mais tarde!"

    },
    sameAccountName: {
        "en-GB": "Account name unavailable, it's already been used.",
        "fr-FR": "Nom de compte indisponible, il a déjà été utilisé.",
        "pt-PT": "O nome da conta não está disponível."
    },
    logout: {
        "en-GB": "Logout",
        "fr-FR": "Déconnecter",
        "pt-PT": "Terminar Sessão"
    },
    creatingAccount: {
        "en-GB": "Creating new account...",
        "fr-FR": "Création d'un nouveau compte...",
        "pt-PT": "A cria uma nova conta..."
    },
    synchronizing: {
        "en-GB": "Synchronizing...",
        "fr-FR": "Synchronisation en cours...",
        "pt-PT": "A sincronizar..."
    },
    lastSynchronization: {
        "en-GB": "Last synchronization",
        "fr-FR": "Dernière synchronisation il y a",
        "pt-PT": "Última sincronização"
    },
    theCollector: {
        "en-GB": "The collector of",
        "fr-FR": "Le collecteur de",
        "pt-PT": "O coletor de"
    },
    hasBeenPutUnderMaintaince: {
        "en-GB": "has been put under maintenance to allow for the issues to be resolved as quickly as possible.",
        "fr-FR": "a été placé en maintenance afin de permettre la correction des problèmes rencontrés le plus rapidement possible.",
        "pt-PT": "foi colocado em manutenção para permitir que os problemas sejam resolvidos o mais rapidamente possível."
    },
    atThisTimeNoAction: {
        "en-GB": "At this time, no action other than deleting existing accounts is possible. We apologize for any inconvenience this may cause.",
        "fr-FR": "Pour le moment, aucune action autre que la suppression des comptes existants est possible. Nous nous excusons pour les inconvénients que cela pourrait vous occasionner.",
        "pt-PT": "Neste momento, nenhuma ação além da exclusão de contas existentes é possível. Pedimos desculpa por qualquer inconveniente que isto possa causar."
    },
    isCurrentlyUnavailable: {
        "en-GB": "is currently unavailable. Unfortunately, we do not have a scheduled fix date for this collector.",
        "fr-FR": "est actuellement indisponible. Nous n'avons malheureusement pas de date de correction prévue pour ce collecteur.",
        "pt-PT": "está atualmente indisponível. Infelizmente, não temos uma data prevista para a correção deste coletor."
    },
    parameters: {
        "en-GB": "Parameters",
        "fr-FR": "Paramètres",
        "pt-PT": "Parâmetros"
    },
    download: {
        "en-GB": "Download",
        "fr-FR": "Téléchargement",
        "pt-PT": "Download"
    },
    metadataExtraction: {
        "en-GB": "Metadata extraction",
        "fr-FR": "Extraction des métadonnées",
        "pt-PT": "Extração de metadados"
    },
    insertFolderName: {
        "en-GB": "Metadata extraction",
        "fr-FR": "Extraction des métadonnées",
        "pt-PT": "Extração de metadados"
    },
    noResultFound: {
        "en-GB": "No result found",
        "fr-FR": "Aucun résultat trouvé",
        "pt-PT": "Nenhum resultado encontrado"
    },
    notReceiveIt: {
        "en-GB": "Didn't receive it?",
        "fr-FR": "Vous ne l'avez pas reçu?",
        "pt-PT": "Não recebeu?"
    },
    receiveNewOne: {
        "en-GB": "Receive a new one",
        "fr-FR": "En recevoir un nouveau",
        "pt-PT": "Receber um novo"
    },
};

export default T9n;