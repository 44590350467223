import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as R from 'ramda';
import _ from '../../constants/i18n/engine';
import T9n from '../../constants/i18n/translations';
import { favoriteAgentIdsList } from '../../constants/utils/agents';


export const AgentListFavourite = ({ agents, accounts, favoriteAgents, setFavoriteAgents }) => {

    // useEffect(() => {

    //     if (!R.isNil(agents)) {

    //         let favoriteAgentsList = agents.filter(favoriteAgent => favoriteAgentIdsList.includes(favoriteAgent.id));
    //         setFavoriteAgents(favoriteAgentsList);

    //     }
    //     // eslint-disable-next-line
    // }, [agents, setFavoriteAgents]);
    const { userId } = useParams();
    let favoriteAgentsIds = favoriteAgents.map(a => a.id.replaceAll("-", ""));
    let favoriteAgentsToDisplay = agents.filter(a => favoriteAgentsIds.includes(a.id));


    return (
        null
        // <section className="stars cover py-4">
        //     <h1 className="sr-only visually-hidden">SBX Webview - Favorites</h1>
        //     <div className="container container-list">
        //         <div className="stars-scrollbar flex-row">
        //             {!R.isNil(favoriteAgentsToDisplay) && !R.isEmpty(favoriteAgentsToDisplay) ?
        //                 favoriteAgentsToDisplay
        //                     .sort(function (a, b) {
        //                         if (a.displayName === b.displayName) return 0;
        //                         return a.displayName > b.displayName ? 1 : -1;
        //                     })
        //                     .map((agent, id) => {
        //                         let currentAccounts = accounts?.filter(a => a.agentId === agent.id) || [];
        //                         let accountLength = currentAccounts.length;
        //                         let hasAccounts = accountLength > 0;
        //                         let agentDisplayName = (agent.name.toLowerCase() === 'impotsv2') ? 'Impots' : agent.displayName;

        //                         let lastAccount = currentAccounts[currentAccounts.length - 1];
        //                         let lastAccountId = lastAccount ? lastAccount.customerAccountId : 'new-account';

        //                         return (
        //                             <Link to={`/u/${userId}/agents/${agent.id}/${lastAccountId}`} key={id} className={"card align-items-center border-none bg-none me-2 mt-2 text-truncate" + (hasAccounts ? " active" : "")}>
        //                                 <div className={"card-img-top circle" + (hasAccounts ? " prime" : "")}>
        //                                     <img className="m-auto img-fluid" src={(agent.name.toLowerCase() === 'impotsv2') ? "https://az859819.vo.msecnd.net/agents/logos/medium/impots.png" : agent.logos?.medium.url || "https://az859819.vo.msecnd.net/agents/logos/medium/unknown.png"} alt={agent.displayName + " logo"} height="48" width="48" />
        //                                 </div>
        //                                 <h2 className="fs-sm text-center ff-aux text-truncate fw-medium mt-2">{agentDisplayName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' - ')[0]}</h2>
        //                             </Link>

        //                         );
        //                     })
        //                 :
        //                 <div className="flex-column me-2">
        //                     <div className="circle is-loading">
        //                         <div className="agents-small mx-auto" aria-hidden="true"></div>
        //                     </div>
        //                     <h1 className="fs-sm text-center ff-aux text-truncate truncate fw-medium mt-2">{_(T9n.loading)}</h1>
        //                 </div>
        //             }
        //         </div>
        //     </div>
        // </section>
    );
};









