export const files = [
    {
        id: 1,
        name: "SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway",
        extension: ".pdf",
        size: "16",
        createAt: new Date("2019-04-10").getTime(),
    },
    {
        id: 2,
        name: "Colored Bird",
        extension: ".pdf",
        size: "32",
        createAt: new Date("2010-04-08").getTime(),
    },
    {
        id: 3,
        name: "Boat in Spain",
        extension: ".pdf",
        size: "45",
        createAt: new Date("2015-04-06").getTime(),
    },
    {
        id: 4,
        name: "Text file",
        extension: ".doc",
        size: "56",
        createAt: new Date("2001-04-20").getTime(),
    },
    {
        id: 5,
        name: "Spreadsheet file",
        extension: ".pdf",
        size: "5.5",
        createAt: new Date("2021-04-20").getTime(),
    },
    {
        id: 6,
        name: "Calendar file",
        extension: ".pdf",
        size: "5.5",
        createAt: new Date("2021-04-20").getTime(),
    },
    {
        id: 7,
        name: "Email message file",
        extension: ".pdf",
        size: "5",
        createAt: new Date("2021-03-05").getTime(),
    },
    {
        id: 8,
        name: "Presentation file",
        extension: ".pdf",
        size: "255",
        createAt: new Date("2021-03-05").getTime(),
    },
    {
        id: 9,
        name: "Disk image",
        extension: ".img",
        size: "255",
        createAt: new Date("2021-03-05").getTime(),
    },
    {
        id: 10,
        name: "Font file",
        extension: ".ttf",
        size: "25",
        createAt: new Date("2017-02-09").getTime(),
    },
    {
        id: 11,
        name: "Acrobat file",
        extension: ".pdf",
        size: "5",
        createAt: new Date("2017-02-13").getTime(),
    },
    {
        id: 12,
        name: "Code file",
        extension: ".pdf",
        size: "2",
        createAt: new Date("2017-02-13").getTime(),
    },
    {
        id: 13,
        name: "Unpreviewable image file",
        extension: ".pdf",
        size: "25",
        createAt: new Date("2017-02-19").getTime(),
    },
    {
        id: 14,
        name: "Vector image file",
        extension: ".pdf",
        size: "25",
        createAt: new Date("2017-02-19").getTime(),
    },
    {
        id: 15,
        name: "Audio file",
        extension: ".pdf",
        size: "14",
        createAt: new Date("2017-02-29").getTime(),
    },
    {
        id: 16,
        name: "Compressed file",
        extension: ".pdf",
        size: "149",
        createAt: new Date("2021-01-18").getTime(),
    },
    {
        id: 17,
        name: "Incomplete file",
        extension: ".pdf",
        size: "1",
        createAt: new Date("2021-01-19").getTime(),
    },
    {
        id: 18,
        name: "Video file",
        extension: ".mov",
        size: "500",
        createAt: new Date("2017-08-30").getTime(),
    },
    {
        id: 19,
        name: "Application or executable file",
        extension: ".dmg",
        size: "52",
        createAt: new Date("2021-09-26").getTime(),
    },
    {
        id: 20,
        name: "Default file",
        extension: ".pdf",
        size: "56",
        createAt: new Date("2021-07-18").getTime(),
    }
];;
// export const files = [
//     {
//         id: 1,
//         name: "SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway SnowMan-Norway",
//         extension: ".jpg",
//         size: "16MB",
//         createAt: "10/04/2021",
//     },
//     {
//         id: 2,
//         name: "Colored Bird",
//         extension: ".tiff",
//         size: "32MB",
//         createAt: "08/04/2021",
//     },
//     {
//         id: 3,
//         name: "Boat in Spain",
//         extension: ".gif",
//         size: "45MB",
//         createAt: "06/04/2021",
//     },
//     {
//         id: 4,
//         name: "Text file",
//         extension: ".doc",
//         size: "56KB",
//         createAt: "20/04/2021",
//     },
//     {
//         id: 5,
//         name: "Spreadsheet file",
//         extension: ".xlsx",
//         size: "5.5MB",
//         createAt: "20/04/2021",
//     },
//     {
//         id: 6,
//         name: "Calendar file",
//         extension: ".cal",
//         size: "5.5MB",
//         createAt: "20/04/2021",
//     },
//     {
//         id: 7,
//         name: "Email message file",
//         extension: ".msg",
//         size: "5KB",
//         createAt: "05/03/2021",
//     },
//     {
//         id: 8,
//         name: "Presentation file",
//         extension: ".ppt",
//         size: "255KB",
//         createAt: "05/03/2021",
//     },
//     {
//         id: 9,
//         name: "Disk image",
//         extension: ".img",
//         size: "255KB",
//         createAt: "05/03/2021",
//     },
//     {
//         id: 10,
//         name: "Font file",
//         extension: ".ttf",
//         size: "25KB",
//         createAt: "09/02/2017",
//     },
//     {
//         id: 11,
//         name: "Acrobat file",
//         extension: ".pdf",
//         size: "5MB",
//         createAt: "13/02/2017",
//     },
//     {
//         id: 12,
//         name: "Code file",
//         extension: ".aspx",
//         size: "2KB",
//         createAt: "13/02/2017",
//     },
//     {
//         id: 13,
//         name: "Unpreviewable image file",
//         extension: ".bmp",
//         size: "25KB",
//         createAt: "19/02/2017",
//     },
//     {
//         id: 14,
//         name: "Vector image file",
//         extension: ".svg",
//         size: "25KB",
//         createAt: "19/02/2017",
//     },
//     {
//         id: 15,
//         name: "Audio file",
//         extension: ".aiff",
//         size: "14MB",
//         createAt: "29/02/2017",
//     },
//     {
//         id: 16,
//         name: "Compressed file",
//         extension: ".zip",
//         size: "149MB",
//         createAt: "18/01/2021",
//     },
//     {
//         id: 17,
//         name: "Incomplete file",
//         extension: ".part",
//         size: "1KB",
//         createAt: "19/01/2021",
//     },
//     {
//         id: 18,
//         name: "Video file",
//         extension: ".mov",
//         size: "500MB",
//         createAt: "30/08/2017",
//     },
//     {
//         id: 19,
//         name: "Application or executable file",
//         extension: ".dmg",
//         size: "52MB",
//         createAt: "26/09/2021",
//     },
//     {
//         id: 20,
//         name: "Default file",
//         extension: ".indd",
//         size: "56KB",
//         createAt: "18/07/2021",
//     }
// ]