import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { Toast } from "bootstrap";

import { getLastSynchronizationByAccountId } from 'src/actions/auth-api';
import * as actionTypes from '../../../constants/actiontypes/auth';
import { clearAccountErrors } from 'src/actions/auth-api';

// Localization
import _ from '../../../constants/i18n/engine';
import T9n from '../../../constants/i18n/translations';

// Components
import { AgentDetailsEmpty } from './agent_details-empty';
import { AgentDetailsDefault } from './agent_details-default';


export const AgentDetails = ({ setShowDetail, showDetail }) => {

    const dispatch = useDispatch();
    const { agentId } = useParams();
    const { location } = useHistory();

    const agentState = useSelector(state => state.agents);
    const accountState = useSelector(state => state.auth);
    const featureState = useSelector(state => state.feature);
    const customizationState = useSelector(state => state.customization);
    const [agent, setAgent] = useState(agentState.agents.find(agent => agent.id === agentId));

    const toastRef = useRef();
    const toast = Toast.getOrCreateInstance(toastRef.current);


    useLayoutEffect(() => {
        setAgent(agentState.agents.find(agent => agent.id === agentId));

        if (!showDetail) {

            setShowDetail(true);
        }

        // eslint-disable-next-line
    }, [location]);



    useEffect(() => {

        if (accountState.isError.includes(actionTypes.ADD_ACCOUNT_FAILURE)) {

            toast.show();
            dispatch(clearAccountErrors());

        }

        // eslint-disable-next-line	
    }, [accountState]);



    useEffect(() => {

        if (!R.isNil(agentId) && !R.isNil(accountState.accounts) && !R.isEmpty(accountState.accounts)) {
            const accounts = accountState.accounts.filter(account => account.agentId === agentId);
            accounts.map((account) => dispatch(getLastSynchronizationByAccountId(account.customerAccountId)));
        }

        // eslint-disable-next-line
    }, [agentId]);



    return (
        <React.Fragment>
            <section className={"offcanvas offcanvas-bottom offcanvas-all p-g2"+ (!showDetail || R.isNil(agentId) ? "" : " show")}>
                <h1 className="sr-only visually-hidden">SBX Webview - View</h1>
                {R.isNil(agent) ?
                    <AgentDetailsEmpty />
                    :
                    <AgentDetailsDefault agent={agent} featureState={featureState} customizationState={customizationState} accountState={accountState} setShowDetail={setShowDetail} />}
            </section>
            <div className="toast text-white bg-danger border-0 z-index-master position-absolute" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef} style={{ zIndex: "3333333", top: "10px", right: "10px" }}>
                <div className="d-flex">
                    <div className="toast-body">{_(T9n.CreateAccountToaster)}</div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </React.Fragment>
    );
};


